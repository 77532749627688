<template>
  <lee-picker
      v-model="isValue"
      v-model:show="isShow"
      :title="title"
      :option-height="optionHeight"
      :visible-option-num="visibleOptionNum"
      :columns="myColumns"
      @onOpen="onOpen"
      @onClose="onClose"
      @onConfirm="onConfirm"
      @onCancel="onCancel"
      @onChange="onChange"
      :resetTop="1"
  />
</template>
<script>
import {toRefs, computed} from 'vue'
import LeePicker from '@/leeplus/LeePicker';

export default {
    name: 'LeeDatePicker',
    inheritAttrs: false,
    components: {
        LeePicker
    },
    emits: ['update:modelValue', 'update:show', 'onChange', 'onOpen', 'onClose', 'onConfirm', 'onCancel', 'onClickOption'],
    setup(props, {emit}) {
        const {show, modelValue, minDate, maxDate,formatter} = toRefs(props)
        const isShow = computed({
            get: () => show.value,
            set: val => emit('update:show', val)
        })
        const isValue = computed({
            get: () => modelValue.value,
            set: val => emit('update:modelValue', val)
        })
        const onOpen= () => {
            emit('onOpen')
        }
        const onClose = () => {
            emit('onClose')
        }
        const onConfirm = (rs) => {
            emit('onConfirm', rs)
        }
        const onCancel = () => {
            emit('onCancel')
        }
        const onChange = (rs) => {
            emit('onChange', rs)
        }

        const myColumns = computed(() => {
            let last = []
            let yearBegin = minDate.value.getFullYear()
            let yearEnd = maxDate.value.getFullYear()
            let monthMin = minDate.value.getMonth()
            let monthMax= maxDate.value.getMonth()
            let dayMin = minDate.value.getDate()
            let dayMax = maxDate.value.getDate()
            for (let i = yearBegin; i <= yearEnd; i++) {
                let monthArray = []
                let monthObj = []
                let monthBegin, monthEnd
                if (i === yearBegin) {
                    monthBegin = monthMin
                    monthEnd = 12
                } else if (i === yearEnd) {
                    monthBegin = 1
                    monthEnd = monthMax
                } else {
                    monthBegin = 1
                    monthEnd = 12
                }
                for (let k = monthBegin; k <= monthEnd; k++) {
                    let dayArray = []
                    let dayObj = []
                    let dayBegin, dayEnd
                    if (i === yearBegin) {
                        if (k === monthBegin) {
                            dayBegin = dayMin
                            dayEnd = new Date(i, k, 0).getDate()
                        } else {
                            dayBegin = 1
                            dayEnd = new Date(i, k, 0).getDate()
                        }
                    } else if (i === yearEnd) {
                        if (k === monthEnd) {
                            dayBegin = 1
                            dayEnd = dayMax
                        } else {
                            dayBegin = 1
                            dayEnd = new Date(i, k, 0).getDate()
                        }
                    } else {
                        dayBegin = 1
                        dayEnd = new Date(i, k, 0).getDate()
                    }
                    for (let t = dayBegin; t <= dayEnd; t++) {
                        let tz = t >= 10 ? t : 0 + String(t)
                        dayArray.push({text: String(tz)+formatter.value[2], value: tz})
                    }
                    dayObj[k] = dayArray
                    let kz = k >= 10 ? k : 0 + String(k)
                    monthArray.push({text: String(kz)+formatter.value[1], value: kz, children: dayObj[k]})
                }
                monthObj[i] = monthArray
                last.push({text: String(i)+formatter.value[0], value: i, children: monthObj[i]})

            }
            return last

        })
        return {
            isShow, isValue, myColumns, onConfirm,onOpen,onClose,onCancel,onChange
        }

    },
    props: {
        modelValue: {
            type: Array,
            default() {
                return []
            }
        },
        formatter: {
            type: Array,
            default() {
                return ['','','']
            }
        },
        show: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        minDate: {
            type: Date,
            default: new Date(new Date().getFullYear() - 5, new Date().getMonth() + 1, new Date().getDate())
        },
        maxDate: {
            type: Date,
            default: new Date(new Date().getFullYear() + 5, new Date().getMonth() + 1, new Date().getDate())
        },
        optionHeight: {
            type: [String, Number],
            default: 44
        },
        visibleOptionNum: {
            type: [String, Number],
            default: 6
        },
    }
};
</script>
<style>
.lee-picker {
  font-size: 16px;
  user-select: none;
}

.lee-picker__toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
}

.lee-picker__cancel {
  color: var(--lee-gray);
}

.lee-picker__confirm {
  color: var(--lee-blue);
}

.lee-picker__cancel, .lee-picker__confirm {
  height: 100%;
  padding: 0 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.lee-picker__columns {
  display: flex;
  cursor: grab;
  position: relative;
}

.lee-picker__column {
  flex: 1;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.lee-picker__mask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, .9), rgba(255, 255, 255, .4)), linear-gradient(0deg, rgba(255, 255, 255, .9), rgba(255, 255, 255, .4));
  background-repeat: no-repeat;
  background-position: top, bottom;
  transform: translateZ(0);
  pointer-events: none;
}

.lee-picker-column__item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  color: var(--lee-back-dark);
}

.lee-picker__frame {
  position: absolute;
  top: 50%;
  right: 16px;
  left: 16px;
  z-index: 2;
  transform: translateY(-50%);
  pointer-events: none;
  background: transparent;
}

.lee-picker__frame:after {
  border-width: 1px 0;
  position: absolute;
  content: " ";
  pointer-events: none;
  left: 0;
  right: 0;
  height: calc(100% - 2px);
  border-style: solid;
  border-color: var(--lee-gray-light);
}

</style>
