<template>
  <i class="lee-icon" :class="{'lee-badge__wrapper':dot||badge}" v-if="name.indexOf('http')>-1">
    <img :src="name" :style="'font-size:'+size+'px'">
    <lee-badge dot v-if="dot" from></lee-badge>
    <lee-badge :content="badge" v-if="badge" from></lee-badge>
  </i>
  <i v-bind="$attrs" class="lee-icon" :class="[{'lee-badge__wrapper':dot||badge},'lee-icon-'+name]" v-else
     :style="[colorStyle,sizeStyle]">
    <lee-badge dot v-if="dot" from></lee-badge>
    <lee-badge :content="badge" v-if="badge" from></lee-badge>
  </i>
</template>
<script>
import {toRgb} from './lee'
import {toRefs, computed} from 'vue'
import LeeBadge from '@/leeplus/LeeBadge';
export default {
    name: 'LeeIcon',
    inheritAttrs: false,
    components:{
        LeeBadge
    },
    setup(props) {
        const {color, size} = toRefs(props)
        const sizeStyle = computed(() => {
            if (size.value === 18) {
                return ''
            } else {
                return 'font-size:' + size.value + 'px'
            }

        })
        const colorStyle = computed(() => {
            if (toRgb(color.value) === 'rgb(0,0,0)') {
                return ''
            } else {
                return 'color:' + color.value
            }
        })
        return {
            sizeStyle, colorStyle
        }
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        color: {
            type: String,
            default: '#000'
        },
        size: {
            type: Number,
            default: 18
        },
        dot: {
            type: Boolean,
            default: false
        },
        badge: {
            type: String,
            default: ''
        },

    }
};
</script>
<style>
.lee-icon {
  display: inline-block;
  font-size: inherit;
}

.lee-icon img {
  width: 1em;
  height: 1em;
  display: block;
}


</style>
