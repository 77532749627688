<template>
  <lee-popup @onOpen="onOpen"  @onClose="onClose" :overlay="false" class="lee-toast" round v-model="show" :class="Class" :duration="0.3">
    <div class="lee-toast__loading" v-if="itype==='loading'">
      <lee-loading :type="loadingType"/>
    </div>
    <lee-icon name="select" v-if="itype==='success'"/>
    <lee-icon name="close" v-if="itype==='fail'"/>
    <lee-icon :name="icon" v-if="icon" :size="36"/>
    <div class="lee-toast__text" v-if="itype!='html'">{{message}}
      <slot></slot>
    </div>
    <div class="lee-toast__text" v-else v-html="message"></div>
  </lee-popup>
</template>
<script>
import {toRefs, ref, watchEffect, computed} from 'vue'
import LeeLoading from '@/leeplus/LeeLoading';
import LeeIcon from '@/leeplus/LeeIcon';
import LeePopup from '@/leeplus/LeePopup';

export default {
    name: 'LeeToast',
    inheritAttrs: false,
    emit: ['onOpen', 'onClose'],
    components: {
        LeeLoading, LeeIcon, LeePopup
    },
    setup(props, {emit}) {
        const st = ref(null)
        const {type, modelValue, duration} = toRefs(props)
        const itype = ref('')
        const typeArray = ['text', 'success', 'fail', 'html', 'loading']
        const show = computed({
            get: () => modelValue.value,
            set: val => emit('update:modelValue', val)
        })
        watchEffect(() => {
            if (typeArray.indexOf(type.value) > -1) {
                itype.value = type.value
            } else {
                itype.value = ''
            }
            if (modelValue.value) {
                if (duration.value > 0) {
                    st.value = setTimeout(() => {
                        emit('update:modelValue', false)
                        emit('onClose')
                        clearTimeout(st.value)
                    }, duration.value * 1000)
                }
            }
        })

        const Class = computed(() => {
            if (itype.value) {
                return 'lee-toast-' + itype.value
            } else {
                return ''
            }
        })
        const onOpen = () => {
            emit('onOpen')
        }
        const onClose = () => {
            emit('onClose')
        }

        return {
            itype, Class,show,onOpen,onClose
        }
    },
    props: {
        modelValue: {
            type: Boolean,
            default: false
        },
        message: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        loadingType: {
            type: String,
            default: 'circle'
        },
        duration: {
            type: [Number, String],
            default: '3'
        },
    }
};
</script>
<style>
.lee-toast {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  width: 88px;
  min-height: 88px;
  padding: 16px;
  color: var(--lee-white);
  font-size: 14px;
  text-align: center;
  background: rgba(0, 0, 0, .7);
  border-radius: 8px;
}

.lee-toast .lee-toast__text {
  margin-top: 8px;
}

.lee-toast-text .lee-toast__text, .lee-toast-html .lee-toast__text {
  margin-top: 0;
}

.lee-toast .lee-loading-icon, .lee-toast .lee-icon {
  color: var(--lee-white);
  font-size: 36px
}


.lee-toast-text, .lee-toast-html {
  width: fit-content;
  min-width: 96px;
  min-height: 0;
  padding: 8px 12px;
}

/*动效*/
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.fade-enter-to, .fade-leave-from {
  opacity: 1;
}
</style>
