<template>
  <lee-popup @onOpen="onOpen" @onClose="onClose" position="bottom" :close-on-click-overlay="false" closeable
             :round="round" v-model="show"
             class="lee-calendar__popup" :duration="0.3">
    <div class="lee-calendar">
      <div class="lee-calendar__header">
        <div class="lee-calendar__header--title">{{title}}</div>
        <div class="lee-calendar__header--subtitle">{{curDate}}</div>
        <div class="lee-calendar__header--weekdays">
          <span v-for="(item,xh) in week" :key="xh">{{item}}</span>
        </div>
      </div>
      <div class="lee-calendar__body">
        <div class="lee-calendar__month" v-for="(item,xh) in myarr" :key="xh">
          <div class="lee-calendar__month--title" v-if="xh">
            {{item.year}}年{{item.month}}月
          </div>
          <div class="lee-calendar__days" :style="checkStyle">
            <div class="lee-calendar__days--curMonth">{{item.month}}</div>
            <div class="lee-calendar__day" @click="check(item,it)"
                 :class="{mid:pdMid(item,it),disabled:pdDisabled(item,inx),cur:pdCur(item,it)}"
                 v-for="(it,inx) in item.days" :key="inx" :style="kong(item.kong,inx)">
              {{it}}
              <span class="lee-calendar__day--bottomInfo" v-if="pdBegin(item,it)">{{checkDayType('start')}}</span>
              <span class="lee-calendar__day--bottomInfo" v-if="pdEnd(item,it)">{{checkDayType('end')}}</span>
              <span class="lee-calendar__day--topInfo">{{checkDay(item.month,it)}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="lee-calendar__footer">
        <lee-button :disabled="checkDisabled" @click="onConfirm" round class="lee-calendar__confirm" type="primary"
                    :color="color"
                    :text-color="textColor">
          {{checkDisabled?confirmDisabledText:confirmText}}
        </lee-button>
      </div>
    </div>
  </lee-popup>
</template>
<script>
import {toRgb, dateDiff, getNextDate, getIndex, monthDiff} from './lee'
import {toRefs, computed, ref, onMounted, nextTick, watchEffect} from 'vue'
import LeePopup from '@/leeplus/LeePopup'
import LeeButton from '@/leeplus/LeeButton'
import {showToast} from '@/leeplus'

export default {
    name: 'LeeCalendar',
    inheritAttrs: false,
    emit: ['onConfirm', 'onOpen', 'onClose',],
    components: {
        LeePopup, LeeButton
    },
    setup(props, {emit}) {
        const iType = ref('')
        const curDate = ref('')
        const iMinDate = ref('')//最小的日期
        const iMaxDate = ref('')//最大的日期
        const week = ref([])
        const myarr = ref([])
        const year = new Date().getFullYear()
        const month = new Date().getMonth() + 1
        const day = new Date().getDate()
        const selectSingle = ref({})
        const selectMultiple = ref([])
        const begin = ref({})
        const end = ref({})
        const {modelValue, color, textColor, minDate, maxDate, maxRange, type, dayInfo} = toRefs(props)
        const typeArray = ['single', 'multiple', 'range']
        if (typeArray.indexOf(type.value) > -1) {
            iType.value = type.value
        } else {
            iType.value = 'single'
        }
        onMounted(() => {
            week.value = ['一', '二', '三', '四', '五', '六', '日']
            //
            let tMonth = new Date().getMonth()//当前日期月份
            let bMonth, eMonth;//日历范围最小月份，最大月份

            if (minDate.value === '') {
                iMinDate.value = new Date()
            } else {
                iMinDate.value = new Date(minDate.value)
            }
            if (maxDate.value === '') {
                let total = tMonth + 6
                let n = new Date();
                n.setMonth(total)
                iMaxDate.value = new Date(n.getFullYear(), n.getMonth(), day)
            } else {
                iMaxDate.value = new Date(maxDate.value)
            }
            bMonth = monthDiff(iMinDate.value, new Date())
            eMonth = monthDiff(new Date(), iMaxDate.value)

            for (let i = tMonth - bMonth; i <= tMonth + eMonth; i++) {
                let n = new Date();
                n.setMonth(i)
                let totalDay = new Date(n.getFullYear(), (n.getMonth() + 1), 0).getDate()
                let kong = new Date(n.getFullYear(), (n.getMonth()), 1).getDay()
                if (kong === 0) {
                    kong = 7
                }
                let days = []
                for (let d = 1; d <= totalDay; d++) {
                    days.push(d)
                }
                myarr.value.push({imonth: i + 1, year: n.getFullYear(), month: n.getMonth() + 1, kong, days})
            }

            //初始默认选中的日期为今天

            if (iType.value === 'single') {
                if (bMonth >= 0 && eMonth >= 0) {
                    selectSingle.value = {
                        imonth: tMonth + 1,
                        year: year,
                        month: tMonth + 1,
                        item: day
                    }
                } else {
                    selectSingle.value = {
                        imonth: myarr.value[0].imonth,
                        year: myarr.value[0].year,
                        month: myarr.value[0].month,
                        item: iMinDate.value.getDate()
                    }
                }

            } else if (iType.value === 'multiple') {
                if (bMonth >= 0 && eMonth >= 0) {
                    selectMultiple.value.push({
                        imonth: tMonth + 1,
                        year: year,
                        month: tMonth + 1,
                        item: day
                    })
                } else {
                    selectMultiple.value.push({
                        imonth: myarr.value[0].imonth,
                        year: myarr.value[0].year,
                        month: myarr.value[0].month,
                        item: iMinDate.value.getDate()
                    })

                }

            } else {
                if (bMonth >= 0 && eMonth >= 0) {
                    begin.value.imonth = tMonth + 1
                    begin.value.year = year
                    begin.value.month = tMonth + 1
                    begin.value.item = day
                    let beginDate = begin.value.year + '-' + begin.value.month + '-' + begin.value.item
                    let nextDate = getNextDate(beginDate, 1)
                    end.value.imonth = nextDate.month > tMonth + 1 ? tMonth + 2 : tMonth + 1
                    end.value.year = nextDate.year
                    end.value.month = nextDate.month
                    end.value.item = nextDate.day
                } else {
                    begin.value.imonth = myarr.value[0].imonth
                    begin.value.year = myarr.value[0].year
                    begin.value.month = myarr.value[0].month
                    begin.value.item = iMinDate.value.getDate()
                    let beginDate = begin.value.year + '-' + begin.value.month + '-' + begin.value.item
                    let nextDate = getNextDate(beginDate, 1)
                    end.value.imonth = nextDate.month > myarr.value[0].month ? myarr.value[0].imonth + 1 : myarr.value[0].imonth
                    end.value.year = nextDate.year
                    end.value.month = nextDate.month
                    end.value.item = nextDate.day
                }
            }

        })
        watchEffect(() => {
            if (modelValue.value) {
                nextTick(() => {
                    let monthDom = document.querySelectorAll('.lee-calendar__month')
                    const observer = new IntersectionObserver(nodes => {
                        nodes.forEach(v => {
                            if (v.isIntersecting) { // 判断是否进入可视区域
                                let cmt = v.target.parentNode.querySelector('.lee-calendar__month--title')
                                if (cmt) {
                                    curDate.value = cmt.innerText
                                } else {
                                    curDate.value = iMinDate.value.getFullYear() + '年' + (iMinDate.value.getMonth() + 1) + '月'
                                }
                            }
                        });
                    }, {
                        root: document.querySelector('.lee-calendar__body'),
                        threshold: 1,
                        rootMargin: '0px 0px 10px 0px'
                    });
                    monthDom.forEach(v => observer.observe(v.querySelector('.lee-calendar__days')));
                })
            }
        })
        const pdMid = (item, it) => {
            if (iType.value === 'range') {
                if (begin.value.month && end.value.month) {
                    if (begin.value.imonth === end.value.imonth) {
                        if (item.month === begin.value.month && item.year === begin.value.year && it > begin.value.item && it < end.value.item) {
                            return true
                        }
                    } else {
                        if (item.imonth > begin.value.imonth && item.imonth < end.value.imonth || it > begin.value.item && item.month === begin.value.month && item.year === begin.value.year || it < end.value.item && item.month === end.value.month && item.year === end.value.year) {
                            return true
                        }
                    }
                }
            }
        }

        const pdDisabled = (item, inx) => {
            let bMonth = iMinDate.value.getMonth() + 1
            let bYear = iMinDate.value.getFullYear()
            let bDay = iMinDate.value.getDate()
            let eMonth = iMaxDate.value.getMonth() + 1
            let eYear = iMaxDate.value.getFullYear()
            let eDay = iMaxDate.value.getDate()
            if (bMonth === item.month && bYear === item.year && inx + 1 < bDay) {
                return true
            }
            if (eMonth === item.month && eYear === item.year && inx + 1 > eDay) {
                return true
            }
        }
        const pdCur = (item, it) => {
            if (iType.value === 'single') {
                if (it === selectSingle.value.item && item.imonth === selectSingle.value.imonth) {
                    return true
                }
            } else if (iType.value === 'multiple') {
                let fiData = selectMultiple.value.filter((el) => {
                    return el.item === it && item.imonth === el.imonth
                })
                if (fiData.length > 0) {
                    return true
                }
            } else {
                // if (it === selectSingle.value.item && item.imonth === selectSingle.value.imonth) {
                //     return true
                // }
                if (item.imonth === begin.value.imonth && it === begin.value.item) {
                    return true
                }
                if (item.imonth === end.value.imonth && it === end.value.item) {
                    return true
                }
            }
        }
        const pdBegin = (item, it) => {
            return item.imonth === begin.value.imonth && it === begin.value.item
        }
        const pdEnd = (item, it) => {
            return item.imonth === end.value.imonth && it === end.value.item
        }
        const kong = (num, inx) => {
            if (inx === 0) {
                return 'margin-left:' + (num - 1) * 14.285 + '%'

            } else {
                return ''
            }
        }
        const check = (item, it) => {
            if (iType.value === 'single') {
                selectSingle.value = {
                    imonth: item.imonth,
                    year: item.year,
                    month: item.month,
                    item: it
                }
            } else if (iType.value === 'multiple') {
                let fiData = selectMultiple.value.filter((el) => {
                    return el.item === it && el.imonth === item.imonth
                })
                if (fiData.length < 1) {
                    if (selectMultiple.value.length >= maxRange.value && maxRange.value != '') {
                        showToast('最多选择' + maxRange.value + '天')
                    } else {
                        selectMultiple.value.push({
                            imonth: item.imonth,
                            year: item.year,
                            month: item.month,
                            item: it
                        })
                    }
                } else {
                    //点击的项删除
                    let hitItem = {
                        imonth: item.imonth,
                        year: item.year,
                        month: item.month,
                        item: it
                    }
                    let index = getIndex(selectMultiple.value, hitItem)
                    selectMultiple.value.splice(index, 1)
                }

            } else {
                if (!begin.value.month) {
                    begin.value.imonth = item.imonth
                    begin.value.year = item.year
                    begin.value.month = item.month
                    begin.value.item = it
                } else {
                    if (!end.value.month) {
                        if (item.imonth === begin.value.imonth) {
                            if (it > begin.value.item) {
                                let beginDate = begin.value.year + '-' + begin.value.month + '-' + begin.value.item
                                let endDate = item.year + '-' + item.month + '-' + it
                                if (dateDiff(beginDate, endDate) >= maxRange.value && maxRange.value != '') {
                                    showToast('最多选择' + maxRange.value + '天')
                                    let checkDate = getNextDate(beginDate, maxRange.value - 1)
                                    end.value.imonth = item.imonth
                                    end.value.year = checkDate.year
                                    end.value.month = checkDate.month
                                    end.value.item = checkDate.day
                                } else {
                                    end.value.imonth = item.imonth
                                    end.value.year = item.year
                                    end.value.month = item.month
                                    end.value.item = it
                                }
                            } else {
                                begin.value.imonth = item.imonth
                                begin.value.year = item.year
                                begin.value.month = item.month
                                begin.value.item = it
                            }
                        } else if (item.imonth > begin.value.imonth) {
                            let beginDate = begin.value.year + '-' + begin.value.month + '-' + begin.value.item
                            let endDate = item.year + '-' + item.month + '-' + it
                            if (dateDiff(beginDate, endDate) >= maxRange.value && maxRange.value != '') {
                                showToast('最多选择' + maxRange.value + '天')
                                let checkDate = getNextDate(beginDate, maxRange.value - 1)
                                let fiData = myarr.value.filter((el) => {
                                    return el.year === checkDate.year && el.month === checkDate.month
                                })
                                end.value.imonth = fiData[0].imonth//这个还没解决
                                end.value.year = checkDate.year
                                end.value.month = checkDate.month
                                end.value.item = checkDate.day
                            } else {
                                end.value.imonth = item.imonth
                                end.value.year = item.year
                                end.value.month = item.month
                                end.value.item = it
                            }
                        } else {
                            begin.value.imonth = item.imonth
                            begin.value.year = item.year
                            begin.value.month = item.month
                            begin.value.item = it
                        }

                    } else {
                        begin.value = {}
                        end.value = {}
                        begin.value.imonth = item.imonth
                        begin.value.year = item.year
                        begin.value.month = item.month
                        begin.value.item = it
                    }
                }
            }


        }
        const show = computed({
            get: () => modelValue.value,
            set: val => emit('update:modelValue', val)
        })
        const checkStyle = computed(() => {
            if (toRgb(color.value) === 'rgb(255,255,255)') {
                return '--color:#1989fa;--tcolor:#fff'
            } else {
                return '--color:' + color.value + ';--tcolor:' + textColor.value
            }
        })
        const checkDisabled = computed(() => {
            if (iType.value === 'range') {
                if (begin.value.item && end.value.item) {
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }

        })
        const onOpen = () => {
            emit('onOpen')
        }
        const onClose = () => {
            emit('onClose')
        }
        const onConfirm = () => {
            let date = ''
            emit('update:modelValue', false)
            if (iType.value === 'single') {
                date = selectSingle.value.year + '/' + selectSingle.value.month + '/' + selectSingle.value.item
                emit('onConfirm', new Date(date))
            } else if (iType.value === 'multiple') {
                date = selectMultiple.value
                emit('onConfirm', date)
            } else {
                let bDate = new Date(begin.value.year + '/' + begin.value.month + '/' + begin.value.item)
                let eDate = new Date(end.value.year + '/' + end.value.month + '/' + end.value.item)
                date = [bDate, eDate]
                emit('onConfirm', date)
            }
            setTimeout(() => {
                emit('onClose')
            }, 300)
        }
        const checkDay = (month, day) => {
            if (dayInfo.value.days) {
                let fi = dayInfo.value.days.filter((el) => {
                    return el.month === month && el.day === day
                })
                if (fi.length > 0) {
                    return fi[0].text
                }
            }
        }
        const checkDayType = (text) => {
            if (iType.value === 'range') {
                if (dayInfo.value.type) {
                    if (text === 'start') {
                        return dayInfo.value.type.start
                    }
                    if (text === 'end') {
                        return dayInfo.value.type.end
                    }
                } else {
                    if (text === 'start') {
                        return '开始'
                    }
                    if (text === 'end') {
                        return '结束'
                    }
                }
            }
        }
        //滚动到指定日期
        const scrollToDate = (date) => {
            let goDate = new Date(date)
            let year = goDate.getFullYear()
            let month = goDate.getMonth() + 1
            let day = goDate.getDate()
            let fi = myarr.value.filter((el) => {
                return el.year === year && el.month === month
            })
            if (iType.value === 'single') {
                selectSingle.value = {
                    imonth: fi[0].imonth,
                    year: year,
                    month: month,
                    item: day
                }
            } else if (iType.value === 'multiple') {
                selectMultiple.value = []
                selectMultiple.value.push({
                    imonth: fi[0].imonth,
                    year: year,
                    month: month,
                    item: day
                })

            } else {
                begin.value = {}
                end.value = {}
                begin.value.imonth = fi[0].imonth
                begin.value.year = year
                begin.value.month = month
                begin.value.item = day
                let beginDate = begin.value.year + '-' + begin.value.month + '-' + begin.value.item
                let nextDate = getNextDate(beginDate, 1)
                end.value.imonth = nextDate.month > month ? begin.value.imonth + 1 : begin.value.imonth
                end.value.year = nextDate.year
                end.value.month = nextDate.month
                end.value.item = nextDate.day
                //滚动上去
                nextTick(() => {
                    let curDay = document.querySelector('.cur')
                    let curMonth = curDay.parentNode.parentNode
                    curMonth.scrollIntoView({behavior: "smooth", block: "end"});
                })


            }
        }
        return {
            year,
            month,
            show,
            week,
            myarr,
            pdMid,
            pdDisabled,
            pdCur,
            pdBegin,
            pdEnd,
            kong,
            check,
            checkStyle,
            checkDisabled,
            curDate,
            onConfirm,
            checkDay,
            checkDayType,
            scrollToDate,
            onOpen,
            onClose

        }
    },
    props: {
        modelValue: {
            type: Boolean,
            default: false
        },
        round: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'single'//single,multiple,range
        },
        title: {
            type: String,
            default: '日期选择'
        },
        color: {
            type: String,
            default: '#fff'
        },
        textColor: {
            type: String,
            default: '#000'
        },
        confirmText: {
            type: String,
            default: '确定'
        },
        confirmDisabledText: {
            type: String,
            default: '确定'
        },
        maxDate: {
            type: String,
            default: ''
        },
        minDate: {
            type: String,
            default: ''
        },
        maxRange: {
            type: [Number, String],
            default: ''//单位天
        },
        dayInfo: {
            type: Object,
            default() {
                return {}
            }
        },
    }
};
</script>
<style>
.lee-calendar__popup {
  height: 80%;
}

.lee-calendar {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--lee-white);
}

.lee-calendar__header {
  flex-shrink: 0;
  box-shadow: 0 2px 10px rgba(125, 126, 128, .16);
}

.lee-calendar__header--title {
  font-size: 16px;
}

.lee-calendar__header--subtitle, .lee-calendar__month--title {
  font-size: 14px;
}

.lee-calendar__month--title, .lee-calendar__header--title, .lee-calendar__header--subtitle {
  color: var(--lee-back-dark);
  height: 44px;
  font-weight: 600;
  line-height: 44px;
  text-align: center;
}

.lee-calendar__header--weekdays {
  display: flex;
}

.lee-calendar__header--weekdays span {
  flex: 1;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
}

.lee-calendar__body {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.lee-calendar__days {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  user-select: none;
}

.lee-calendar__days--curMonth {
  position: absolute;
  font-size: 226px;
  color: #f6f6f6;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.lee-calendar__day {
  position: relative;
  width: 14.285%;
  height: 64px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lee-calendar__day .lee-calendar__day--bottomInfo {
  font-size: 12px;
  bottom: 6px;
  position: absolute;
}

.lee-calendar__day .lee-calendar__day--topInfo {
  font-size: 12px;
  top: 6px;
  position: absolute;
}

.lee-calendar__day.cur {
  background: var(--color);
  color: var(--tcolor);
  border-radius: 4px;
}

.lee-calendar__day.mid {
  color: var(--color);
  position: relative;
}

.lee-calendar__day.mid:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: currentColor;
  opacity: .1;
  content: "";

}

.lee-calendar__day.disabled {
  color: var(--lee-disabled);
  cursor: default;
  pointer-events: none;
}


.lee-calendar__footer {
  flex-shrink: 0;
  padding-left: 16px;
  padding-right: 16px;
}

.lee-calendar__confirm {
  height: 36px;
  margin: 7px 0;
  display: block;
  width: 100%;
}

</style>
