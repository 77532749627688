<template>
  <div class="lee-switch" :style="[colorStyle,sizeStyle]"
       :class="{'lee-switch-on':modelValue,'lee-switch-disabled':disabled}"
       @click="toggle">
    <div class="lee-switch-dot">
      <slot name="dot"></slot>
    </div>
  </div>
</template>
<script>
import {toRefs, computed} from 'vue'
import {toRgb} from './lee'

export default {
    name: 'LeeSwitch',
    inheritAttrs: false,
    setup(props, {emit}) {
        const {modelValue, disabled, color, size} = toRefs(props)
        const toggle = () => {
            if (!disabled.value) {
                emit('update:modelValue', !modelValue.value)
            }
        }
        const colorStyle = computed(() => {
            if (toRgb(color.value) === 'rgb(7,193,96)') {
                return ''
            } else {
                if (modelValue.value) {
                    return 'color:' + color.value + ';background:' + color.value
                } else {
                    return ''
                }
            }
        })
        const sizeStyle = computed(() => {
            if (size.value === 30) {
                return ''
            } else {
                return 'font-size:' + size.value + 'px'
            }
        })
        return {
            toggle, colorStyle, sizeStyle
        }
    },
    props: {
        color: {
            type: String,
            default: '#07C160'
        },
        size: {
            type: Number,
            default: 30
        },
        disabled: {
            type: Boolean,
            default: false
        },
        modelValue: {
            type: Boolean,
            default: false
        },

    }
};
</script>
<style>
.lee-switch {
  position: relative;
  display: inline-block;
  width: 1.8em;
  height: 1em;
  font-size: 30px;
  background: var(--lee-bg-gray);
  border-radius: .5em;
  cursor: pointer;
  transition: background-color .2s ease-in-out;
  color: var(--lee-gray-light);
}

.lee-switch-on {
  background: var(--lee-green);
  color: var(--lee-green);
}

.lee-switch-dot {
  position: absolute;
  transition: all .1s ease-in-out;
  background: var(--lee-bg-white);
  border-radius: 100%;
  width: calc(1em - 4px);
  height: calc(1em - 4px);
  left: 2px;
  top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lee-switch-on .lee-switch-dot {
  transform: translateX(0.8em);
}

.lee-switch-disabled {
  cursor: not-allowed;
  opacity: .3;
}

.lee-switch-dot .lee-icon {
  font-size: .5em;
  font-weight: bold;
  color: currentColor;
}

</style>
