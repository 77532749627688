<template>
  <div class="lee-index-anchor" :class="{'lee-index-anchor--sticky':activeLetter===index}">{{index}}</div>
</template>
<script>
import {inject} from 'vue'

export default {
    name: 'LeeIndexAnchor',
    inheritAttrs: false,
    setup() {
        const {activeLetter}=inject('indexBar')
        return {
            activeLetter
        }
    },
    props: {
        index: {
            type: String,
            default: ''
        }
    }
};
</script>
<style>
.lee-index-anchor {
  padding: 0 16px;
  color: var(--lee-back-dark);
  font-weight: 600;
  font-size: 14px;
  line-height: 32px;
}

.lee-index-anchor--sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  right: 0;
  left: 0;
  color: var(--lee-blue);
  background: var(--lee-white);
  border-bottom: solid 1px var(--lee-gray-light);
  z-index: 2;
}
</style>
