<template>
  <transition name="fade">
    <div  v-show="show" class="lee-dropdown-item" :class="'lee-dropdown-item--'+idirection" :style="styles">
      <lee-popup class="lee-dropdown-item__content" :duration="duration" :closeOnClickOverlay="closeOnClickOverlay"
                 :overlay="overlay" v-model="show" :position="redirection" teleport="" @onClose="onClose"
                 @onOpen="onOpen">
        <lee-cell @click="hit(item)" :class="{'lee-dropdown-item__option--active':item.value===modelValue}"
                  v-for="(item,xh) in options" :key="xh" :title="item.text" class="clickable lee-dropdown-item__option"
                  :style="color(item)">
          <template #footer v-if="item.value===modelValue">
            <i class="lee-icon lee-icon-select"></i>
          </template>
        </lee-cell>
      </lee-popup>
    </div>
  </transition>
</template>
<script>
import {toRgb} from './lee'
import {ref, inject, toRefs, watch, computed} from 'vue'

export default {
    name: 'LeeDropdownItem',
    inheritAttrs: false,
    emits: ['update:modelValue','onChange', 'onOpen', 'onClose'],
    setup(props, {emit}) {
        const show = ref(false)
        const idirection = ref('')
        const redirection = ref('')
        const {top} = inject('dom')
        const {index, allData, resetIndex,setIndex} = inject('setData')
        const {options, modelValue} = toRefs(props)
        const {activeColor, direction, duration, overlay, closeOnClickOverlay} = inject('parent')
        const diArray = ['down', 'up']
        if (diArray.indexOf(direction.value) > -1) {
            idirection.value = direction.value
        } else {
            idirection.value = 'down'
        }
        watch(idirection, (v) => {
            if (v === 'down') {
                redirection.value = 'top'
            } else {
                redirection.value = 'bottom'
            }
        }, {immediate: true})
        watch(index, (v) => {
            if (JSON.stringify(allData[v]) === JSON.stringify(options.value)) {
                show.value = true
            } else {
                show.value = false
            }
        }, {immediate: true})

        const styles = computed(() => {
            if (idirection.value === 'down') {
                return '--d:' + duration.value + 's;top:' + top.value + 'px'
            } else {
                return '--d:' + duration.value + 's;bottom:' + top.value + 'px'
            }
        })
        const color = (item) => {
            if (toRgb(activeColor.value) === 'rgb(25,137,250)') {
                return ''
            } else {
                if (item.value === modelValue.value) {
                    return 'color:' + activeColor.value
                }
            }
        }
        const hit = (item) => {
            emit('update:modelValue', item.value)
            emit('onChange', item.value)
            resetIndex()
            setTimeout(() => {
                emit('onClose')
            }, 300)
        }
        const onOpen = () => {
            emit('onOpen')
        }
        const onClose = () => {
            emit('onClose')
            resetIndex()
        }
        let wflag = ref(null)
        const toggle = (flag) => {
            let flagArray = [true, false]
            if (flagArray.indexOf(flag) > -1) {
                wflag.value = flag
            } else {
                wflag.value = false
            }
            if (wflag.value === true) {
                let a=Object.keys(allData).filter((el)=>{
                    return JSON.stringify(allData[el]) === JSON.stringify(options.value)
                })
                setIndex(a[0])
            }else{
                setIndex('')
            }
        }


        return {
            top,
            show,
            idirection,
            redirection,
            toggle,
            onClose,
            onOpen,
            index,
            duration,
            overlay,
            closeOnClickOverlay,
            styles,
            color,
            hit
        }
    },
    props: {
        modelValue: {
            type: [Number, String],
            default: ''
        },
        options: {
            type: Array,
            default() {
                return []
            }
        },
    }
};
</script>
<style>
.lee-dropdown-item {
  position: fixed;
  right: 0px;
  left: 0px;
  z-index: 199;
  overflow: hidden;
}

.lee-dropdown-item .lee-mark {
  position: absolute;
}

.lee-dropdown-item__content {
  position: absolute;
  max-height: 80%;
}

.lee-dropdown-item__option--active {
  color: var(--lee-blue);
}

.lee-dropdown-item__option--active .lee-cell__footer {
  color: currentColor;
}

.lee-dropdown-item--down {
  bottom: 0px;
}

.lee-dropdown-item--up {
  top: 0px;
}

/*动效*/
.fade-enter-active, .fade-leave-active {
  transition: opacity var(--d);
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.fade-enter-to, .fade-leave-from {
  opacity: 1;
}
</style>
