<template>
  <lee-popup round v-model="isShow" position="bottom" class="lee-picker" @onOpen="onOpen" @onClose="onClose">
    <div class="lee-picker__toolbar">
      <div type="button" class="lee-picker__cancel" @click="onCancel">取消</div>
      <div class="lee-picker__title">{{title}}</div>
      <div type="button" class="lee-picker__confirm" @click="onConfirm">确认</div>
    </div>
    <div class="lee-picker__columns" :style="'height:'+(visibleOptionNum*optionHeight)+'px'">
      <div @mouseleave="mouseleave(xh)" @mousedown="mousedown(xh)" @mousemove="mousemove(xh)" @mouseup="mouseup(xh)"
           @touchstart="touchstart(xh)" @touchmove="touchmove(xh)" @touchend="touchend(xh)"
           class="lee-picker__column"
           v-for="(item,xh) in colNum" :key="xh">
        <ul class="lee-picker-column__wrapper"
            :style="{transition:isDown[xh]?'all .0s':'all .2s ease-in-out',transform:'translateY(' + (orginalY[xh] + moveY[xh]) + 'px)'}">
          <li @click.stop="go(xh,inx)" v-for="(it,inx) in list(xh)" :key="inx" class="lee-picker-column__item"
              :style="'height:'+optionHeight+'px'">{{it.text}}
          </li>
        </ul>
      </div>
      <div class="lee-picker__mask"
           :style="'background-size:100% '+((visibleOptionNum*0.5-1)*optionHeight+optionHeight*0.5)+'px'"></div>
      <div class="lee-picker__frame" :style="'height:'+optionHeight+'px'"></div>
    </div>
  </lee-popup>
</template>
<script>
import {ref, toRefs, computed, onMounted, watch} from 'vue'

export default {
    name: 'LeePicker',
    inheritAttrs: false,
    emits: ['update:modelValue', 'update:show', 'onChange', 'onOpen', 'onClose', 'onConfirm', 'onCancel', 'onClickOption'],
    setup(props, {emit}) {
        const {columns, show, resetTop, optionHeight, visibleOptionNum, modelValue} = toRefs(props)
        const isShow = computed({
            get: () => show.value,
            set: val => emit('update:show', val)
        })
        const colNum = computed(() => {
            if (Array.isArray(columns.value[0])) {
                return columns.value.length
            } else {
                let data = columns.value;
                let k = 1
                if (data[0].children) {
                    do {
                        k++
                        data = data[0].children;
                    } while (data[0].children != undefined);
                    return k
                } else {
                    return k
                }
            }
        })
        const list = (xh) => {
            if (Array.isArray(columns.value[0])) {
                return columns.value[xh]
            } else {
                if (columns.value[0].children) {
                    if (xh === 0) {
                        return columns.value
                    } else {
                        let data = columns.value
                        if (xh === 1) {
                            data = data[index.value[0]].children
                            return data
                        } else if (xh === 2) {
                            data = data[index.value[0]].children
                            data = data[index.value[1]].children
                            return data
                        } else {
                            data = data[index.value[0]].children
                            data = data[index.value[1]].children
                            data = data[index.value[2]].children
                            return data
                        }
                    }

                } else {
                    return columns.value
                }

            }
        }
        //
        let whichXh = ref(null)//表示点击哪列
        let isClick = ref(false)//这个是为了解决是点击还是mouseup冲突问题
        let moveY = ref([])
        let orginalY = ref([])
        let oldY = ref([])
        let isDown = ref([])
        let maxY = ref([])//滚动到顶的最大值
        let minY = ref([])//滚动到底的最大值
        let index = ref([])//这个是转化为选中的索引


        onMounted(() => {
            let halfNum = Math.ceil(visibleOptionNum.value * 0.5) - 1
            for (let i = 0; i < colNum.value; i++) {
                moveY.value[i] = 0
                if (visibleOptionNum.value % 2 > 0) {
                    maxY.value[i] = halfNum * optionHeight.value
                } else {
                    let halfHeight = optionHeight.value * 0.5
                    maxY.value[i] = halfNum * optionHeight.value + halfHeight
                }
                minY.value[i] = maxY.value[i] - (list(i).length - 1) * optionHeight.value
                if (modelValue.value[i] === undefined) {
                    modelValue.value[i] = list(i)[0].value
                } else {
                    let fi=list(i).some((el) => {
                        return String(el.value).indexOf(String(modelValue.value[i]))>-1
                    })
                    if(!fi){
                        modelValue.value[i] = list(i)[0].value
                    }
                }

                //modelValue.value[i] = modelValue.value[i] === undefined ? list(i)[0].value : modelValue.value[i]
                list(i).forEach((el, xhh) => {
                    if (el.value == modelValue.value[i]) {
                        index.value[i] = xhh
                    }
                })
                goTo(i, index.value[i])
            }
        })
        //动作
        const mouseleave = (xh) => {
            if (isDown.value[xh]) {//这个判断很重要
                mouseup(xh)
            }
        }
        const mousedown = (xh) => {
            oldY.value[xh] = event.pageY
            isDown.value[xh] = true
        }
        const mousemove = (xh) => {
            if (isDown.value[xh]) {
                moveY.value[xh] = event.pageY - oldY.value[xh]
                if (orginalY.value[xh] + moveY.value[xh] >= maxY.value[xh] + optionHeight.value) {
                    moveY.value[xh] = maxY.value[xh] - orginalY.value[xh] + optionHeight.value
                }
                if (orginalY.value[xh] + moveY.value[xh] <= minY.value[xh] - optionHeight.value) {
                    moveY.value[xh] = minY.value[xh] - orginalY.value[xh] - optionHeight.value
                }
            }

        }
        const mouseup = (xh) => {
            if (Math.abs(moveY.value[xh]) > 0) {
                isClick.value = false
            } else {
                isClick.value = true
            }
            if (orginalY.value[xh] + moveY.value[xh] >= maxY.value[xh]) {
                moveY.value[xh] = maxY.value[xh] - orginalY.value[xh]
            }
            if (orginalY.value[xh] + moveY.value[xh] <= minY.value[xh]) {
                moveY.value[xh] = minY.value[xh] - orginalY.value[xh]
            }
            orginalY.value[xh] = orginalY.value[xh] + moveY.value[xh]
            isDown.value[xh] = false
            moveY.value[xh] = 0
            //根据位置转换第几个,并且位置矫正
            index.value[xh] = Math.round((maxY.value[xh] - orginalY.value[xh]) / optionHeight.value)
            orginalY.value[xh] = maxY.value[xh] - index.value[xh] * optionHeight.value
            whichXh.value = xh


        }
        const touchstart = (xh) => {
            oldY.value[xh] = event.targetTouches[0].pageY
            isDown.value[xh] = true
        }
        const touchmove = (xh) => {
            if (isDown.value[xh]) {
                moveY.value[xh] = event.targetTouches[0].pageY - oldY.value[xh]
                if (orginalY.value[xh] + moveY.value[xh] >= maxY.value[xh] + optionHeight.value) {
                    moveY.value[xh] = maxY.value[xh] - orginalY.value[xh] + optionHeight.value
                }
                if (orginalY.value[xh] + moveY.value[xh] <= minY.value[xh] - optionHeight.value) {
                    moveY.value[xh] = minY.value[xh] - orginalY.value[xh] - optionHeight.value
                }
            }
        }
        const touchend = (xh) => {
            let num = list(xh).length
            minY.value[xh] = maxY.value[xh] - (num - 1) * optionHeight.value
            if (orginalY.value[xh] + moveY.value[xh] >= maxY.value[xh]) {
                moveY.value[xh] = maxY.value[xh] - orginalY.value[xh]
            }
            if (orginalY.value[xh] + moveY.value[xh] <= minY.value[xh]) {
                moveY.value[xh] = minY.value[xh] - orginalY.value[xh]
            }
            orginalY.value[xh] = orginalY.value[xh] + moveY.value[xh]
            moveY.value[xh] = 0
            isDown.value[xh] = false
            //根据位置转换第几个,并且位置矫正
            index.value[xh] = Math.round((maxY.value[xh] - orginalY.value[xh]) / optionHeight.value)
            orginalY.value[xh] = maxY.value[xh] - index.value[xh] * optionHeight.value
            whichXh.value = xh


        }
        const reset = (xh) => {
            for (let i = xh; i < colNum.value; i++) {
                if (resetTop.value === 0) {
                    orginalY.value[i + 1] = maxY.value[i + 1]//下级归位到顶部
                    index.value[i + 1] = 0
                } else {
                    if (orginalY.value[i + 1] < minY.value[i + 1]) {
                        orginalY.value[i + 1] = maxY.value[i + 1]//下级归位到顶部
                        index.value[i + 1] = 0
                    }
                }
            }
        }


        const goTo = (xh, position) => {
            orginalY.value[xh] = maxY.value[xh] - position * optionHeight.value
        }
        const go = (xh, inx) => {
            if (!isClick.value) {
                return
            }
            whichXh.value = xh
            index.value[xh] = inx
            goTo(xh, inx)
        }
        //观察,只有变动值index，才促发onChange
        watch(index, (v) => {
            if (v && show.value) {
                onChange()
            }
        }, {
            deep: true
        })

        //事件
        const getResult = () => {
            let value = []
            let text = []
            for (let i = 0; i < colNum.value; i++) {
                value.push(list(i)[index.value[i]].value)
                text.push(list(i)[index.value[i]].text)
            }
            return {text: [...text], value: [...value]}
        }

        const onOpen = () => {
            emit('onOpen')
        }
        const onClose = () => {
            emit('onClose')
        }
        const onConfirm = () => {
            emit('onConfirm', getResult())
            emit('update:show', false)
            let st = setTimeout(() => {
                emit('onClose')
                clearTimeout(st)
            }, 1000)
        }
        const onCancel = () => {
            emit('onCancel')
            emit('update:show', false)
            let st = setTimeout(() => {
                emit('onClose')
                clearTimeout(st)
            }, 1000)
        }
        const onChange = () => {
            for (let i = 0; i < colNum.value; i++) {
                minY.value[i] = maxY.value[i] - (list(i).length - 1) * optionHeight.value
                if(list(whichXh.value)[0].children){
                    reset(whichXh.value)
                }

            }
            emit('update:modelValue', getResult().value)
            emit('onChange', getResult())
        }
        return {
            isShow,
            colNum,
            list,
            mousedown,
            mousemove,
            mouseup,
            mouseleave,
            touchstart,
            touchmove,
            touchend,
            orginalY,
            moveY,
            isDown,
            onOpen,
            onClose,
            onConfirm,
            onCancel,
            onChange,
            go,
            getResult

        }
    },
    props: {
        modelValue: {
            type: Array,
            default() {
                return []
            }
        },
        resetTop: {
            type: Number,
            default: 0//0重置下级，1只有下级高度不够才置顶
        },
        show: {
            type: Boolean,
            default: false
        },
        columns: {
            type: Array,
            default() {
                return []
            }
        },
        title: {
            type: String,
            default: ''
        },
        optionHeight: {
            type: [String, Number],
            default: 44
        },
        visibleOptionNum: {
            type: [String, Number],
            default: 6
        },
    }
};
</script>
<style>
.lee-picker {
  font-size: 16px;
  user-select: none;
}

.lee-picker__toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
}

.lee-picker__cancel {
  color: var(--lee-gray);
}

.lee-picker__confirm {
  color: var(--lee-blue);
}

.lee-picker__cancel, .lee-picker__confirm {
  height: 100%;
  padding: 0 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.lee-picker__columns {
  display: flex;
  cursor: grab;
  position: relative;
}

.lee-picker__column {
  flex: 1;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.lee-picker__mask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, .9), rgba(255, 255, 255, .4)), linear-gradient(0deg, rgba(255, 255, 255, .9), rgba(255, 255, 255, .4));
  background-repeat: no-repeat;
  background-position: top, bottom;
  transform: translateZ(0);
  pointer-events: none;
}

.lee-picker-column__item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  color: var(--lee-back-dark);
}

.lee-picker__frame {
  position: absolute;
  top: 50%;
  right: 16px;
  left: 16px;
  z-index: 2;
  transform: translateY(-50%);
  pointer-events: none;
  background: transparent;
}

.lee-picker__frame:after {
  border-width: 1px 0;
  position: absolute;
  content: " ";
  pointer-events: none;
  left: 0;
  right: 0;
  height: calc(100% - 2px);
  border-style: solid;
  border-color: var(--lee-gray-light);
}

</style>
