<template>
  <div class="lee-collapse-item">
    <lee-cell :disabled="disabled" :footer="footer" @click="toggle" :icon="icon" :title="title" :is-link="isLink"
              class="lee-collapse-item__title"
              :class="{'lee-collapse-item__title--expanded':check,'lee-collapse-item__title--disabled':disabled}">
      <template #title>
        <slot name="title"></slot>
      </template>
      <template #footer>
        <slot name="footer"></slot>
      </template>
      <template #right-icon>
        <slot name="right-icon"></slot>
      </template>
    </lee-cell>
    <transition name="leetran" @enter="enter" @after-enter="afterenter" @leave="leave" @after-leave="afterleave">
      <div class="lee-collapse-item__wrapper" v-show="check">
        <div class="lee-collapse-item__content">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import LeeCell from './LeeCell'
import {inject, computed, toRefs} from 'vue'

export default {
    name: 'LeeCollapseItem',
    inheritAttrs: false,
    components: {
        LeeCell
    },
    setup(props) {
        const {modelValue, accordion, change, exp, up} = inject('collapse')
        const {name, disabled} = toRefs(props)
        const check = computed(() => {
            if (accordion.value) {
                return modelValue.value === name.value
            } else {
                return modelValue.value.indexOf(name.value) > -1
            }
        })
        const toggle = (str) => {
            if (typeof (str) === 'object' || typeof (str) === 'undefined') {
                if (!disabled.value) {
                    change(name.value)
                }
            }
            if (typeof (str) === 'boolean') {
                if (str === true) {
                    if (!disabled.value) {
                        exp(name.value)
                    }
                } else {
                    if (!disabled.value) {
                        up(name.value)
                    }
                }
            }

        }
        const enter = (el) => {
            var h = el.scrollHeight
            el.style.setProperty('--h', h + 'px')
        }
        const afterenter = () => {
            //el.removeAttribute('style')
        }
        const leave = (el) => {
            var h = el.scrollHeight
            el.style.setProperty('--h', h + 'px')
        }
        const afterleave = () => {
            //el.style.setProperty('--h', '')
        }


        return {modelValue, accordion, check, toggle, enter, afterenter, leave, afterleave}
    },
    props: {
        name: {
            type: [String, Number],
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        footer: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        isLink: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        }

    }
};
</script>
<style>
.lee-collapse-item__title .lee-cell-right__icon {
  transform: rotate(90deg);
  transition: transform .3s;
}

.lee-collapse-item__title--expanded .lee-cell-right__icon {
  transform: rotate(-90deg);
}

.lee-collapse-item__title--disabled {
  cursor: not-allowed;
  filter: grayscale(1);
}

.lee-collapse-item__title--disabled .lee-cell__footer, .lee-collapse-item__title--disabled .lee-cell-right__icon, .lee-collapse-item__title--disabled .lee-cell__title, .lee-collapse-item__title--disabled .lee-cell-left__icon {
  opacity: .3;
}

.lee-collapse-item__title--disabled + .lee-collapse-item__wrapper .lee-collapse-item__content {
  color: var(--lee-disabled);
}

.lee-collapse-item__wrapper {
  overflow: hidden;
  position: relative;
}

.lee-collapse-item__wrapper:after {
  content: '';
  position: absolute;
  height: 1px;
  bottom: 0;
  left: 16px;
  right: 16px;
  background: rgba(0, 0, 0, .04);
}

.lee-collapse-item__content {
  padding: 12px 16px;
  color: var(--lee-gray);
  font-size: 14px;
  line-height: 1.5;
  background: var(--lee-white);
}

.leetran-enter-active {
  animation: my-in .3s ease-in-out;
}

.leetran-leave-active {
  animation: my-out .3s ease-in-out;
}

@keyframes my-in {
  0% {
    height: 0
  }

  100% {
    height: var(--h);

  }
}

@keyframes my-out {
  0% {
    height: var(--h);

  }

  100% {
    height: 0
  }
}
</style>
