<template>
  <div class="lee-index-bar">
    <div class="lee-index-bar__sidebar">
      <span @click="scrollTo(xh)" :class="{'lee-index-bar__index--active':item.active}" class="lee-index-bar__index"
            :data-index="item.text"
            v-for="(item,xh) in letterArray" :key="xh">{{item.text}}</span>
    </div>
    <slot></slot>
  </div>
</template>
<script>
import {onMounted, ref, provide} from 'vue'

export default {
    name: 'LeeIndexBar',
    inheritAttrs: false,
    setup(props, {slots}) {
        let positionArray = []
        let letterArray = ref([])
        let activeLetter = ref('')
        let slotsArray = slots.default()
        let letters = slotsArray.filter((el) => {
            return el.type.name === "LeeIndexAnchor"
        })
        letters.forEach((el) => {
            letterArray.value.push({text: el.props.index, active: false})
        })
        const scrollTo = (xh) => {
            if (typeof (xh) === 'number') {
                let IndexAanchor = document.querySelectorAll('.lee-index-anchor')
                IndexAanchor[xh].scrollIntoView();
            } else {
                let IndexAanchor = document.querySelectorAll('.lee-index-anchor')
                IndexAanchor.forEach((el)=>{
                    if(el.innerHTML===xh){
                        el.scrollIntoView();
                    }
                })
            }
        }
        onMounted(() => {
            let IndexAanchor = document.querySelectorAll('.lee-index-anchor')
            IndexAanchor.forEach((el) => {
                positionArray.push(el.getBoundingClientRect().top)
            })
            let offY = IndexAanchor[0].getBoundingClientRect().height
            window.addEventListener('scroll', (e) => {
                let top = e.target.scrollTop
                let fi = positionArray.filter((el) => {
                    return top >= el - offY
                })
                letterArray.value.forEach((el, xh) => {
                    if (xh === fi.length - 1) {
                        el.active = true
                        activeLetter.value = el.text
                    } else {
                        el.active = false
                    }
                })

            }, true)

        })
        provide('indexBar', {activeLetter})

        return {
            letterArray, scrollTo
        }
    }
};
</script>
<style>
.lee-index-bar__sidebar {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  text-align: center;
  transform: translateY(-50%);
  cursor: pointer;
  user-select: none;
}

.lee-index-bar__index {
  padding: 0 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
}

.lee-index-bar__index--active {
  color: var(--lee-blue);
  font-weight: 700;
}
</style>
