//时间戳转时间
const toDate = (timestamp) => {
    let date = new Date(timestamp * 1000); //如果date为10位需要乘1000
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + ' ';
    let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    return Y + M + D + h + m + s;
}
//获取日期差几天
const dateDiff = (beginDate, endDate) => {
    let strSeparator = "-";
    //日期分隔符   let oDate1;   let oDate2;
    let iDays;
    let oDate1 = beginDate.split(strSeparator);
    let oDate2 = endDate.split(strSeparator);
    let strDateS = new Date(oDate1[0], oDate1[1] - 1, oDate1[2]);
    let strDateE = new Date(oDate2[0], oDate2[1] - 1, oDate2[2]);
    iDays = parseInt(Math.abs(strDateS - strDateE) / 1000 / 60 / 60 / 24)
    //把相差的毫秒数转换为天数
    return iDays;
}
//获取日期差几月
const monthDiff = (beginDate, endDate) => {
    //日期分隔符   let oDate1;   let oDate2;
    let iMonth;
    //2012-1   2014-5
    let yDiff =endDate.getFullYear()-beginDate.getFullYear()
    let mDiff=endDate.getMonth()-beginDate.getMonth()
    iMonth=yDiff*12+mDiff
    return iMonth;
}

//获取时间对应几天前，多少小时前
const dateAgo = (date) => {
    let diff, diffSec, diffMin, diffHou, diffDay;
    let now = new Date();
    let old = new Date(date)
    diff = Math.floor((now - old) / 1000);
    diffDay = Math.floor(diff / (60 * 60 * 24));
    diffHou = Math.floor((diff / (60 * 60))) - (diffDay * 24);
    diffMin = Math.floor(diff / (60)) - (diffDay * 24 * 60) - (diffHou * 60);
    diffSec = Math.floor(diff) - (diffDay * 24 * 60 * 60) - (diffHou * 60 * 60) - (diffMin * 60);
    if (diffDay >= 1) {
        return diffDay + "天前"
    } else if (diffHou >= 1) {
        return diffHou + "小时前"
    } else if (diffMin >= 1) {
        return diffMin + "分钟前"
    } else if (diffSec >= 1) {
        return diffSec + "秒前"
    }
}
//获取指定日期的前几天或者后几天（默认后1天）
const getNextDate = (date, numDay = 1) => {
    const nDate = new Date(date)
    nDate.setDate(nDate.getDate() + numDay)
    return {year: nDate.getFullYear(), month: nDate.getMonth() + 1, day: nDate.getDate()}
}


//数组随机排序
const shuffle = (arr) => {
    let i = arr.length;
    while (i) {
        let j = Math.floor(Math.random() * i--);
        [arr[j], arr[i]] = [arr[i], arr[j]];
    }
    return arr
}

//对象在对象数组的索引
const getIndex = (arr, obj) => {
    let index = null;
    let key0 = Object.keys(obj)[0];
    let key3 = Object.keys(obj)[3];
    arr.every(function (value, i) {
        if (value[key0] === obj[key0]&&value[key3] === obj[key3]) {
            index = i;
            return false;
        }
        return true;
    });
    return index;
}
//统计数组中元素出现的次数
const repeatNum = (arr) => {
    return arr.reduce((acc, cur) => {
        if (!acc[cur]) {
            acc[cur] = 1
        } else {
            acc[cur]++
        }
        return acc
    }, {})
}
//将16进制转RGB
const toRgb = (color) => {
    let colors = color.split('#')[1]
    let lastColor = ''
    let zero = ''
    if (colors.length === 3) {
        lastColor = colors.slice(0, 1) + colors.slice(0, 1) + colors.slice(1, 2) + colors.slice(1, 2) + colors.slice(2, 3) + colors.slice(2, 3)

    } else {
        let buzeroNum = 6 - colors.length
        for (let i = 0; i < buzeroNum; i++) {
            zero += '0'
        }
        lastColor = zero + colors
    }
    let r = lastColor.slice(0, 2)
    let r1 = r.slice(0, 1)
    let r2 = r.slice(1, 2)
    let g = lastColor.slice(2, 4)
    let g1 = g.slice(0, 1)
    let g2 = g.slice(1, 2)
    let b = lastColor.slice(4, 6)
    let b1 = b.slice(0, 1)
    let b2 = b.slice(1, 2)
    let R = Number(tonum(r1) * 16) + Number(tonum(r2))
    let G = Number(tonum(g1) * 16) + Number(tonum(g2))
    let B = Number(tonum(b1) * 16) + Number(tonum(b2))
    let rgb = 'rgb(' + R + ',' + G + ',' + B + ')'
    return rgb
}
const tonum = (str) => {
    switch (str) {
        case 'a':
        case 'A':
            str = 10
            break;
        case 'b':
        case 'B':
            str = 11
            break;
        case 'c':
        case 'C':
            str = 12
            break;
        case 'd':
        case 'D':
            str = 13
            break;
        case 'e':
        case 'E':
            str = 14
            break;
        case 'f':
        case 'F':
            str = 15
            break;
    }
    return str
}
//将RGB转16进制
const rgbTo16 = (color) => {
    let lastColor = ''
    let colors = color.split(',')
    if (colors.length != 3) {
        console.warn('你输入的RGB值错误')
        lastColor = '你输入的RGB值错误'
        return lastColor
    }
    let r = colors[0]
    let g = colors[1]
    let b = colors[2]
    let fr = String(toletter(Math.floor(r / 16)))
    let lr = String(toletter(r % 16))
    let fg = String(toletter(Math.floor(g / 16)))
    let lg = String(toletter(g % 16))
    let fb = String(toletter(Math.floor(b / 16)))
    let lb = String(toletter(b % 16))
    lastColor = '#' + fr + lr + fg + lg + fb + lb
    return lastColor
}
const toletter = (str) => {
    switch (str) {
        case 10:
            str = 'a'
            break;
        case 11:
            str = 'b'
            break;
        case 12:
            str = 'c'
            break;
        case 13:
            str = 'd'
            break;
        case 14:
            str = 'e'
            break;
        case 15:
            str = 'f'
            break;
    }
    return str
}


export {toDate, dateDiff,monthDiff, dateAgo, getNextDate, shuffle, repeatNum, getIndex, toRgb, rgbTo16}
