<template>
  <div class="lee-badge__wrapper" v-if="haveDefaultSlots">
    <div v-if="dot" class="lee-badge lee-badge-fixed lee-badge-dot" :class="'lee-badge-'+iPosition"
         :style="checkStyle">
    </div>
    <div v-if="content" class="lee-badge lee-badge-fixed" :class="'lee-badge-'+iPosition"
         :style="checkStyle">{{iContent}}
    </div>
    <div v-if="!content&&haveContentSlots" class="lee-badge lee-badge-fixed" :class="'lee-badge-'+iPosition"
         :style="checkStyle"><slot name="content"></slot>
    </div>
    <slot></slot>
  </div>
  <template v-else>
    <template v-if="haveFrom">
      <div v-if="dot" class="lee-badge lee-badge-fixed lee-badge-dot" :class="'lee-badge-'+iPosition"
           :style="checkStyle">
      </div>
      <div v-if="content" class="lee-badge lee-badge-fixed" :class="'lee-badge-'+iPosition"
           :style="checkStyle">{{iContent}}
      </div>
    </template>
    <template v-else>
      <div v-if="dot" class="lee-badge  lee-badge-dot" :class="'lee-badge-'+iPosition"
           :style="checkStyle" v-bind="$attrs">
      </div>
      <div v-if="content" class="lee-badge" :class="'lee-badge-'+iPosition"
           :style="checkStyle" v-bind="$attrs">{{iContent}}
      </div>
      <div v-if="!content&&haveContentSlots" class="lee-badge" :class="'lee-badge-'+iPosition"
         :style="checkStyle" v-bind="$attrs"><slot name="content"></slot>
    </div>
    </template>
  </template>
</template>
<script>
import {toRgb} from './lee'
import {ref, computed, toRefs, watchEffect} from 'vue'
export default {
    name: 'LeeBadge',
    inheritAttrs: false,
    setup(props, {slots}) {
        const {from, position,max,content,color} = toRefs(props)
        const haveDefaultSlots = computed(() => {
            return slots.default!=undefined
        })
        const haveContentSlots = computed(() => {
            return slots.content!=undefined
        })
        const haveFrom = computed(() => {
            return from.value
        })
        const checkStyle = computed(() => {
            if(toRgb(color.value)!='rgb(238,10,36)'){
                return 'background:'+color.value
            }else{
                return ''
            }

        })
        const iPosition = ref('')
        const iContent = ref('')
        const positionArray = ['top-left', 'top-right', 'bottom-left', 'bottom-right']
        watchEffect(() => {
            if (positionArray.indexOf(position.value) > -1) {
                iPosition.value = position.value
            } else {
                iPosition.value = 'top-right'
            }
            if(typeof(content.value)==='number'){
                iContent.value=content.value>=max.value?max.value+'+':content.value
            }else{
                iContent.value=content.value
            }

        })

        return {
             iPosition, haveDefaultSlots,haveContentSlots, haveFrom,iContent,checkStyle
        }
    },
    props: {
        from: {
            type: Boolean,
            default: false
        },
        dot: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: '#ee0a24'
        },
        content: {
            type: [String,Number],
            default: ''
        },
        position: {
            type: String,
            default: 'top-right'
        },
        max: {
            type: Number,
            default: Infinity
        },

    }
};
</script>
<style>
.lee-badge {
  display: inline-block;
  min-width: 16px;
  padding: 0 3px;
  color: var(--lee-white);
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  background: var(--lee-red);
  border-radius: 999px;
  font-family: -apple-system-font;
}

.lee-badge-dot {
  display: inline-block;
  width: 8px;
  min-width: 0;
  height: 8px;
  background: var(--lee-red);
  border-radius: 100%;
  border: none;
  padding: 0;
}

.lee-badge__wrapper {
  position: relative;
  display: inline-block;
}
.lee-badge-top-left {
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
}
.lee-badge-top-right {
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}
.lee-badge-bottom-left {
  bottom: 0;
  left: 0;
  transform: translate(-50%, 50%);
}
.lee-badge-bottom-right {
  bottom: 0;
  right: 0;
  transform: translate(50%, 50%);
}



.lee-badge-fixed {
  position: absolute;
}
.lee-badge .lee-icon{
  margin: 3px;
  display: inline-block;
  font-size: 12px;
  color: var(--lee-white);
}
</style>
