<template>
  <lee-popup @onOpen="onOpen"  @onClose="onClose" position="bottom" :round="round" v-model="show" class="lee-action-sheet" :duration="duration"
             :closeable="closeable">
    <div class="lee-action-sheet__header" v-if="title">{{title}}<i @click="close"
                                                                   class="lee-icon lee-icon-close lee-action-sheet__close"></i>
    </div>
    <div class="lee-action-sheet__description" v-if="description">{{description}}</div>
    <div class="lee-action-sheet__content">
      <template v-if="actions.length>0">
        <div class="lee-action-sheet__item" v-for="(item,xh) in actions" :key="xh" @click="select(item,xh)"
             :class="classStyle(item)" :style="colorStyle(item)">
          <template v-if="!item.loading">
            <div class="lee-action-sheet__name">{{item.name}}</div>
            <div class="lee-action-sheet__subname" v-if="item.subname">{{item.subname}}</div>
          </template>
          <template v-else>
            <lee-loading/>
          </template>
        </div>
      </template>
      <template v-else>
        <slot></slot>
      </template>
    </div>
    <div class="lee-action-sheet__gap" v-if="cancelText"></div>
    <div class="lee-action-sheet__cancel" v-if="cancelText" @click="cancel">{{cancelText}}</div>
  </lee-popup>
</template>
<script>
import {toRefs,computed} from 'vue'
import LeePopup from '@/leeplus/LeePopup';
import LeeLoading from '@/leeplus/LeeLoading';
export default {
    name: 'LeeActionSheet',
    inheritAttrs: false,
    emits: ['update:modelValue', 'onCancel', 'onOpen', 'onClose', 'onSelect'],
    components: {
        LeePopup,LeeLoading
    }
    ,
    setup(props, {emit}) {
        const {modelValue, closeOnClickAction,} = toRefs(props)
       const show = computed({
            get: () => modelValue.value,
            set: val => emit('update:modelValue', val)
        })
        const classStyle = (item) => {
            if (item.disabled) {
                return 'lee-action-sheet__item--disabled'
            } else if (item.loading) {
                return 'lee-action-sheet__item--loading'
            } else {
                return 'clickable'
            }
        }
        const colorStyle = (item) => {
            if (item.color) {
                return 'color:' + item.color
            }
        }
        const select = (item, xh) => {
            if (closeOnClickAction.value && !item.disabled) {
                emit('onSelect', item, xh)
                setTimeout(() => {
                    emit('update:modelValue', false)
                    emit('onClose')
                }, 300)
            }
        }
        const cancel = () => {
            emit('onCancel')
            setTimeout(() => {
                emit('update:modelValue', false)
                emit('onClose')
            }, 300)
        }
        const close = () => {
            emit('update:modelValue', false)
            emit('onClose')
        }
        const onOpen = () => {
            emit('onOpen')
        }
        const onClose = () => {
            emit('onClose')
        }


        return {
            show, select, cancel, close, colorStyle, classStyle,onOpen,onClose
        }

    },
    props: {
        modelValue: {
            type: Boolean,
            default: false
        },
        closeOnClickAction: {
            type: Boolean,
            default: false
        },
        round: {
            type: Boolean,
            default: false
        },
        actions: {
            type: Array,
            default() {
                return []
            }
        },
        title: {
            type: String,
            default: ''
        },
        cancelText: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        duration: {
            type: [Number, String],
            default: '0.3'
        },
        closeable: {
            type: Boolean,
            default: false
        },

    }
};
</script>
<style>
.lee-action-sheet {
  display: flex;
  flex-direction: column;
  max-height: 80%;
  overflow: hidden;
  color: #000;
}

.lee-action-sheet__content {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.lee-action-sheet__item, .lee-action-sheet__cancel {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  width: 100%;
  padding: 14px 16px;
  font-size: 16px;
  background: var(--lee-white);
  border: none;
  cursor: pointer;
}

.lee-action-sheet__item--disabled {
  opacity: .3;
  cursor: not-allowed;
}

.lee-action-sheet__item--loading {

}


.lee-action-sheet__cancel {
  color: var(--lee-gray);
}

.lee-action-sheet__gap {
  display: block;
  height: 8px;
  background: var(--lee-gray-light);
}

.lee-action-sheet__subname {
  margin-top: 8px;
  color: var(--lee-gray);
  font-size: 12px;
}

.lee-action-sheet__description {
  position: relative;
  padding: 20px 16px;
  color: var(--lee-gray);
  font-size: 14px;
  text-align: center;
}

.lee-action-sheet__description:after {
  position: absolute;
  content: " ";
  pointer-events: none;
  right: 16px;
  bottom: 0;
  left: 16px;
  border-bottom: 1px solid var(--lee-gray-light);
  transform: scaleY(.5);
}

.lee-action-sheet__header {
  font-weight: 600;
  font-size: 16px;
  line-height: 48px;
  text-align: center;
}

.lee-action-sheet__close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  padding: 0 16px;
  color: var(--lee-gray);
  font-size: 16px;
  line-height: inherit;
}
</style>
