<template>
  <div class="lee-collapse">
    <slot></slot>
  </div>
</template>
<script>
import {toRefs, provide} from 'vue'

export default {
    name: 'LeeCollapse',
    inheritAttrs: false,
    setup(props, {emit, slots}) {
        const {accordion, modelValue} = toRefs(props)
        const toggleAll = (str) => {
            let allArray = []
            slots.default().forEach((el) => {
                allArray.push(el.props.name)
            })
            if (!accordion.value) {
                if (typeof (str) === 'undefined') {
                    let fi=allArray.filter((el)=>{
                        return modelValue.value.indexOf(el)<0
                    })
                    emit('update:modelValue', fi)
                }
                if (typeof (str) === 'boolean') {
                    if (str === true) {
                        emit('update:modelValue', allArray)
                    } else {
                        emit('update:modelValue', [])
                    }

                }
            }
        }
        const change = (name) => {
            if (accordion.value) {
                if (modelValue.value === name) {
                    emit('update:modelValue', '')
                } else {
                    emit('update:modelValue', name)
                }

            } else {
                if (modelValue.value.indexOf(name) > -1) {
                    modelValue.value.splice(modelValue.value.indexOf(name), 1)
                } else {
                    modelValue.value.push(name)
                }
                emit('update:modelValue', modelValue.value)
            }
        }
        const exp = (name) => {
            if (accordion.value) {
                emit('update:modelValue', name)
            } else {
                if (modelValue.value.indexOf(name) < 0) {
                    modelValue.value.push(name)
                    emit('update:modelValue', modelValue.value)
                }
            }
        }
        const up = (name) => {
            if (accordion.value) {
                emit('update:modelValue', '')
            } else {
                modelValue.value.splice(modelValue.value.indexOf(name), 1)
                emit('update:modelValue', modelValue.value)
            }
        }

        provide('collapse', {...toRefs(props), change, exp, up})
        return {
            toggleAll
        }
    },
    props: {
        accordion: {
            type: Boolean,
            default: false
        },
        modelValue: [String, Array, Number]
    }
};
</script>
<style>

</style>
