<template>
  <div class="lee-cell-group" :class="{'lee-cell-group--inset':inset}">
    <div class="lee-cell-group__title">{{title}}</div>
    <div class="inset"><slot></slot></div>
    <div class="lee-cell-group__footer" v-if="footer">{{footer}}</div>
  </div>
</template>
<script>
export default {
    name: 'LeeCellGroup',
    inheritAttrs: false,
    setup() {

    },
    props: {
        title: {
            type: String,
            default: '分组'
        },
        footer: {
            type: String,
            default: ''
        },
        inset: {
            type: Boolean,
            default: false
        },
    }
};
</script>
<style>
.lee-cell-group__title,.lee-cell-group__footer{
  font-size: 14px;
  color: var(--lee-gray);
}
.lee-cell-group__title{
    padding: 32px 16px 16px;
    line-height: 16px;
}
.lee-cell-group__footer{
    padding: 6px 16px;
    line-height: 16px;
}
.lee-cell-group--inset .inset{
    margin: 0 16px;
    overflow: hidden;
    border-radius: 8px;
}
</style>
