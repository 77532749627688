import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {IndexAnchor,IndexBar,DatePicker,Picker,Collapse,CollapseItem,Cell, CellGroup, DropdownMenu, DropdownItem,Icon, Badge, Switch, Button, Loading, Popup, Toast,Dialog, ActionSheet,PullRefresh,Tag,Calendar} from '@/leeplus';

const app = createApp(App);
app.use(router)
app.use(Cell)
app.use(CellGroup)
app.use(Icon)
app.use(Badge)
app.use(Tag)
app.use(Switch)
app.use(Button)
app.use(Loading)
app.use(Popup)
app.use(Toast)
app.use(Dialog)
app.use(ActionSheet)
app.use(PullRefresh)
app.use(Calendar)
app.use(DropdownMenu)
app.use(DropdownItem)
app.use(Collapse);
app.use(CollapseItem);
app.use(Picker);
app.use(DatePicker);
app.use(IndexAnchor);
app.use(IndexBar);
app.mount('#app')
