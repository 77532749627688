<template>
  <div class="lee-loading" :class="{'lee-loading-vertical':vertical===true}" v-if="type=='ball-pulse'">
    <div v-if="!haveSlotIcon" class="lee-loading-icon" :class="thetype" :style="[sizeStyle,colorStyle]">
      <span></span><span></span><span></span>
    </div>
    <slot name="icon" v-else></slot>
    <div class="lee-loading-text"  v-if="haveSlot" :style="textColorStyle"><slot></slot></div>
  </div>
  <div class="lee-loading" :class="{'lee-loading-vertical':vertical===true}" v-else-if="type=='ball-wave'">
    <div v-if="!haveSlotIcon" class="lee-loading-icon" :class="thetype" :style="[sizeStyle,colorStyle]">
      <span></span><span></span><span></span>
    </div>
    <slot name="icon" v-else></slot>
    <div class="lee-loading-text"  v-if="haveSlot" :style="textColorStyle"><slot></slot></div>
  </div>
  <div class="lee-loading" :class="{'lee-loading-vertical':vertical===true}" v-else-if="type=='rectangle'">
    <div v-if="!haveSlotIcon" class="lee-loading-icon" :class="thetype" :style="[sizeStyle,colorStyle]">
      <span></span><span></span><span></span>
    </div>
    <slot name="icon" v-else></slot>
    <div class="lee-loading-text"  v-if="haveSlot" :style="textColorStyle"><slot></slot></div>
  </div>
  <div class="lee-loading" :class="{'lee-loading-vertical':vertical===true}" v-else-if="type=='square-move'">
    <div v-if="!haveSlotIcon" class="lee-loading-icon" :class="thetype" :style="[sizeStyle,colorStyle]">
      <span></span><span></span><span></span>
    </div>
    <slot name="icon" v-else></slot>
    <div class="lee-loading-text"  v-if="haveSlot" :style="textColorStyle"><slot></slot></div>
  </div>
  <div class="lee-loading" :class="{'lee-loading-vertical':vertical===true}" v-else-if="type=='take-turn'">
    <div v-if="!haveSlotIcon" class="lee-loading-icon" :class="thetype" :style="[sizeStyle,colorStyle]">
      <span></span><span></span>
    </div>
    <slot name="icon" v-else></slot>
    <div class="lee-loading-text"  v-if="haveSlot" :style="textColorStyle"><slot></slot></div>
  </div>
  <div class="lee-loading" :class="{'lee-loading-vertical':vertical===true}" v-else-if="type=='google'">
    <div v-if="!haveSlotIcon" class="lee-loading-icon" :class="thetype" :style="[sizeStyle,colorStyle]">
      <span></span><span></span><span></span><span></span>
    </div>
    <slot name="icon" v-else></slot>
    <div class="lee-loading-text"  v-if="haveSlot" :style="textColorStyle"><slot></slot></div>
  </div>
  <div class="lee-loading" :class="{'lee-loading-vertical':vertical===true}" v-else-if="type=='radar-2'">
    <div v-if="!haveSlotIcon" class="lee-loading-icon" :class="thetype" :style="[sizeStyle,colorStyle]">
      <span></span>
    </div>
    <slot name="icon" v-else></slot>
    <div class="lee-loading-text"  v-if="haveSlot" :style="textColorStyle"><slot></slot></div>
  </div>
  <div class="lee-loading" :class="{'lee-loading-vertical':vertical===true}" v-else-if="type=='radar-1'">
    <div v-if="!haveSlotIcon" class="lee-loading-icon" :class="thetype" :style="[sizeStyle,colorStyle]">
      <span></span><span></span><span></span>
    </div>
    <slot name="icon" v-else></slot>
    <div class="lee-loading-text"  v-if="haveSlot" :style="textColorStyle"><slot></slot></div>
  </div>
  <div class="lee-loading" :class="{'lee-loading-vertical':vertical===true}" v-else-if="type=='progress'">
    <div v-if="!haveSlotIcon" class="lee-loading-icon" :class="thetype" :style="[sizeStyle,colorStyle]">
      <span></span>
    </div>
    <slot name="icon" v-else></slot>
    <div class="lee-loading-text"  v-if="haveSlot" :style="textColorStyle"><slot></slot></div>
  </div>
  <div class="lee-loading" :class="{'lee-loading-vertical':vertical===true}" v-else-if="type=='zebra-crossing'">
    <div v-if="!haveSlotIcon" class="lee-loading-icon" :class="thetype" :style="[sizeStyle,colorStyle]">
      <span></span>
    </div>
    <slot name="icon" v-else></slot>
    <div class="lee-loading-text"  v-if="haveSlot" :style="textColorStyle"><slot></slot></div>
  </div>
  <div class="lee-loading" :class="{'lee-loading-vertical':vertical===true}" v-else-if="type=='spinner'">
    <div v-if="!haveSlotIcon" class="lee-loading-icon" :class="thetype" :style="[sizeStyle,colorStyle]">
      <span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
    </div>
    <slot name="icon" v-else></slot>
    <div class="lee-loading-text"  v-if="haveSlot" :style="textColorStyle"><slot></slot></div>
  </div>
  <div class="lee-loading" :class="{'lee-loading-vertical':vertical===true}" v-else-if="type=='ball-circle'">
    <div v-if="!haveSlotIcon" class="lee-loading-icon" :class="thetype" :style="[sizeStyle,colorStyle]">
      <span></span><span></span>
    </div>
    <slot name="icon" v-else></slot>
    <div class="lee-loading-text"  v-if="haveSlot" :style="textColorStyle"><slot></slot></div>
  </div>
  <div class="lee-loading" :class="{'lee-loading-vertical':vertical===true}" v-else-if="type=='circle'">
    <div v-if="!haveSlotIcon" class="lee-loading-icon" :class="thetype" :style="[sizeStyle,colorStyle]">
    </div>
    <slot name="icon" v-else></slot>
    <div class="lee-loading-text"  v-if="haveSlot" :style="textColorStyle"><slot></slot></div>
  </div>
  <div class="lee-loading" :class="{'lee-loading-vertical':vertical===true}" v-else-if="type=='circle-side'">
    <div v-if="!haveSlotIcon" class="lee-loading-icon" :class="thetype" :style="[sizeStyle,colorStyle]">
    </div>
    <slot name="icon" v-else></slot>
    <div class="lee-loading-text"  v-if="haveSlot" :style="textColorStyle"><slot></slot></div>
  </div>
  <div class="lee-loading" :class="{'lee-loading-vertical':vertical===true}" v-else-if="type=='circle-arrow'">
    <div v-if="!haveSlotIcon" class="lee-loading-icon" :class="thetype" :style="[sizeStyle,colorStyle]">
    </div>
    <slot name="icon" v-else></slot>
    <div class="lee-loading-text"  v-if="haveSlot" :style="textColorStyle"><slot></slot></div>
  </div>
  <div class="lee-loading" :class="{'lee-loading-vertical':vertical===true}" v-else-if="type=='flip-block'">
    <div v-if="!haveSlotIcon" class="lee-loading-icon" :class="thetype" :style="[sizeStyle,colorStyle]">
    </div>
    <slot name="icon" v-else></slot>
    <div class="lee-loading-text"  v-if="haveSlot" :style="textColorStyle"><slot></slot></div>
  </div>
  <div class="lee-loading" :class="{'lee-loading-vertical':vertical===true}" v-else-if="type=='box'">
    <div v-if="!haveSlotIcon" class="lee-loading-icon" :class="thetype" :style="[sizeStyle,colorStyle]">
      <div class="boxn">
        <span></span><span></span><span></span><span></span><span></span><span></span>
      </div>
    </div>
    <slot name="icon" v-else></slot>
    <div class="lee-loading-text"  v-if="haveSlot" :style="textColorStyle"><slot></slot></div>
  </div>
  <div class="lee-loading" :class="{'lee-loading-vertical':vertical===true}" v-else-if="type=='swim-block'">
    <div v-if="!haveSlotIcon" class="lee-loading-icon" :class="thetype" :style="[sizeStyle,colorStyle]">
      <span></span><span></span><span></span><span></span>
    </div>
    <slot name="icon" v-else></slot>
    <div class="lee-loading-text"  v-if="haveSlot" :style="textColorStyle"><slot></slot></div>
  </div>
  <div class="lee-loading" :class="{'lee-loading-vertical':vertical===true}" v-else-if="type=='croll-block'">
    <div v-if="!haveSlotIcon" class="lee-loading-icon" :class="thetype" :style="[sizeStyle,colorStyle]">
      <span></span><span></span><span></span><span></span><span></span>
    </div>
    <slot name="icon" v-else></slot>
    <div class="lee-loading-text"  v-if="haveSlot" :style="textColorStyle"><slot></slot></div>
  </div>
  <div class="lee-loading" :class="{'lee-loading-vertical':vertical===true}" v-else-if="type=='wave'">
    <div v-if="!haveSlotIcon" class="lee-loading-icon" :class="thetype" :style="[sizeStyle,colorStyle]">
      <span></span>
    </div>
    <slot name="icon" v-else></slot>
    <div class="lee-loading-text"  v-if="haveSlot" :style="textColorStyle"><slot></slot></div>
  </div>
</template>
<script>
import {toRgb} from './lee'
import {toRefs, computed} from 'vue'

export default {
    name: 'LeeLoading',
    inheritAttrs: false,
    setup(props,{slots}) {
        const {color,textColor, size, type} = toRefs(props)
        const haveSlot = computed(() => {
            return slots.default != undefined
        })
        const haveSlotIcon = computed(() => {
            return slots.icon != undefined
        })
        const colorStyle = computed(() => {
            if (toRgb(color.value) === 'rgb(7,193,96)') {
                return ''
            } else {
                return 'color:' + color.value
            }
        })
        const sizeStyle = computed(() => {
            if (size.value === 14) {
                return ''
            } else {
                return 'font-size:' + size.value + 'px'
            }
        })
        const textColorStyle = computed(() => {
            if (toRgb(textColor.value) === 'rgb(7,193,96)') {
                return ''
            } else {
                return 'color:' + textColor.value
            }
        })
        let typeArray = ['circle', 'circle-side', 'circle-arrow', 'rectangle', 'square-move', 'ball-circle', 'ball-wave', 'take-turn', 'ball-pulse', 'radar-1', 'radar-2', 'spinner', 'progress', 'zebra-crossing', 'flip-block', 'box', 'swim-block', 'croll-block', 'wave']
        const thetype = computed(() => {
            if (typeArray.indexOf(type.value) > -1) {
                return 'lee-loading-'+type.value
            } else {
                return 'lee-loading-circle'
            }
        })

        return {
            thetype, colorStyle,sizeStyle,textColorStyle,haveSlot,haveSlotIcon
        }
    },
    props: {
        size: {
            type: Number,
            default: 14 //默认default
        },
        type: {
            type: String,
            default: 'circle' //默认default
        },
        color: {
            type: String,
            default: '#07c160' //默认default
        },
        textColor: {
            type: String,
            default: '#07c160' //默认default
        },
        vertical: {
            type: Boolean,
            default: false
        }
    }
};
</script>
<style>
.lee-loading-icon {
  width: 1em;
  height: 1em;
  color: #07c160;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lee-loading .lee-loading-text{
  font-size: .5em;
  color: #07c160;
  margin-left: 6px;
}
.lee-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.lee-loading .lee-icon{
  animation: circle 1.8s infinite linear;
}
.lee-loading-vertical{
  flex-direction: column;
}
.lee-loading-vertical  .lee-loading-text{
  margin-left: 0px;
  margin-top: 6px;
}

.lee-loading-ball-pulse {
  justify-content: space-between;
}

.lee-loading-ball-pulse span {
  width: 30%;
  padding-bottom: 30%;
  border-radius: 100%;
  background: currentColor;
  animation: ball-pulse 1s infinite;
}

.lee-loading-ball-pulse span:nth-child(2) {
  animation-delay: .3s
}

.lee-loading-ball-pulse span:nth-child(3) {
  animation-delay: .6s
}

.lee-loading-ball-wave {
  justify-content: space-between;
}

.lee-loading-ball-wave span {
  width: 30%;
  padding-bottom: 30%;
  border-radius: 100%;
  background: currentColor;
  animation: ball-wave 1s infinite linear;
}

.lee-loading-ball-wave span:nth-child(2) {
  animation-delay: .3s
}

.lee-loading-ball-wave span:nth-child(3) {
  animation-delay: .6s
}

.lee-loading-rectangle {
  justify-content: space-around;
}

.lee-loading-rectangle span {
  width: 20%;
  height: 50%;
  border-radius: 3px;
  background: currentColor;
  animation: rectangle 1s infinite linear;
}

.lee-loading-rectangle span:nth-child(2) {
  animation-delay: .3s
}

.lee-loading-rectangle span:nth-child(3) {
  animation-delay: .6s
}

.lee-loading-ball-circle {
  position: relative;
}

.lee-loading-ball-circle span {
  position: absolute;
  border-radius: 100%;
  width: 30%;
  height: 30%;
  background: currentColor;
  left: 0;
  top: 0
}

.lee-loading-ball-circle span:nth-child(1) {
  animation: ball-circle-one 1s infinite linear;
  transform: translate(0, 0);
}

.lee-loading-ball-circle span:nth-child(2) {
  animation: ball-circle-two 1s infinite linear;
  transform: translate(28px, 28px);
}

.lee-loading-take-turn {
  justify-content: center;
}

.lee-loading-take-turn span {
  width: 30%;
  padding-bottom: 30%;
  background: currentColor;
}

.lee-loading-take-turn span:nth-child(1) {
  animation: take-turn-one 2s infinite;
  transform: translate(-100%, -0);
}

.lee-loading-take-turn span:nth-child(2) {
  animation: take-turn-two 2s infinite;
  transform: translate(100%, 0);
}

.lee-loading-radar-1 {
  position: relative;
}

.lee-loading-radar-1 span {
  position: absolute;
  box-sizing: border-box;
  border-radius: 100%
}

.lee-loading-radar-1 span:nth-child(1) {
  width: 30%;
  height: 30%;
  background: currentColor;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

}

.lee-loading-radar-1 span:nth-child(2) {
  left: 0;
  top: 0;
  animation: circle 1s infinite linear;
  border-width: 2px;
  border-style: solid;
  border-color: #fff currentColor #fff currentColor;
  width: 100%;
  height: 100%
}

.lee-loading-radar-1 span:nth-child(3) {
  left: 20%;
  top: 20%;
  animation: circlerev 1s infinite linear;
  border-width: 2px;
  border-style: solid;
  border-color: transparent #ddd transparent #ddd;
  width: 60%;
  height: 60%
}

.lee-loading-radar-2 {
  position: relative;
  border-radius: 100%;
  overflow: hidden;
  background-image: -webkit-repeating-radial-gradient(transparent 0%, transparent 25%, currentColor 28%, currentColor 31%, transparent 35%),
  -webkit-linear-gradient(left, transparent 49%, currentColor 49%, currentColor 53%, transparent 53%),
  -webkit-linear-gradient(top, transparent 49%, currentColor 49%, currentColor 53%, transparent 53%)
}

.lee-loading-radar-2 span {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-image: conic-gradient(currentColor 0%, transparent 100%);
  transform: rotate(0deg);
  transform-origin: 50% 50%;
  animation: circle 2.6s linear infinite;
}


.lee-loading-spinner {
  position: relative;
}

.lee-loading-spinner span {
  width: 30%;
  height: 15%;
  position: absolute;
  border-radius: 3px;
  background: currentColor;
  animation: tm .7s infinite linear;
}

.lee-loading-spinner span:nth-child(1) {
  left: 0;
  top: 50%;
  margin-top: -7.5%;
}

.lee-loading-spinner span:nth-child(2) {
  animation-delay: .1s;
  left: 25%;
  top: 25%;
  transform: rotate(45deg);
  margin-top: -7.5%;
  margin-left: -17.5%;
}

.lee-loading-spinner span:nth-child(3) {
  animation-delay: .2s;
  left: 50%;
  top: 0%;
  transform: rotate(90deg);
  margin-left: -17.5%;
  margin-top: 7.5%;
}

.lee-loading-spinner span:nth-child(4) {
  animation-delay: .3s;
  right: 25%;
  top: 25%;
  transform: rotate(135deg);
  margin-top: -7.5%;
  margin-right: -17.5%;
}

.lee-loading-spinner span:nth-child(5) {
  animation-delay: .4s;
  right: 0%;
  top: 50%;
  transform: rotate(180deg);
  margin-top: -7.5%;
}

.lee-loading-spinner span:nth-child(6) {
  animation-delay: .5s;
  right: 25%;
  bottom: 25%;
  transform: rotate(225deg);
  margin-bottom: -7.5%;
  margin-right: -17.5%;
}

.lee-loading-spinner span:nth-child(7) {
  animation-delay: .6s;
  left: 50%;
  bottom: 0%;
  transform: rotate(270deg);
  margin-left: -17.5%;
  margin-bottom: 7.5%
}

.lee-loading-spinner span:nth-child(8) {
  animation-delay: .7s;
  left: 25%;
  bottom: 25%;
  transform: rotate(315deg);
  margin-bottom: -7.5%;
  margin-left: -17.5%;
}

.lee-loading-square-move {
  position: relative;
}

.lee-loading-square-move span {
  position: absolute;
  border-radius: 4px;
  width: 30%;
  height: 30%;
  background: currentColor;
  left: 0;
  top: 0
}

.lee-loading-square-move span:nth-child(1) {
  animation: square-move-one 2s infinite linear;
  transform: translate(50%, 50%);

}

.lee-loading-square-move span:nth-child(2) {
  animation: square-move-two 2s infinite linear;
  transform: translate(50%, 175%);

}

.lee-loading-square-move span:nth-child(3) {
  animation: square-move-three 2s infinite linear;
  transform: translate(175%, 175%);
}

.lee-loading-google {
  width: 24px;
  height: 24px;
  position: relative;
}

.lee-loading-google span {
  position: absolute;
  border-radius: 100%;
  width: 37.5%;
  height: 37.5%;
  background: currentColor;
  left: 0;
  top: 0;
  animation: take-turn1 880ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

@keyframes ball-pulse {
  0% {
    transform: scale(1)
  }

  50% {
    transform: scale(0)
  }

  100% {
    transform: scale(1)
  }
}

@keyframes rectangle {
  0% {
    transform: scaleY(1)
  }

  50% {
    transform: scaleY(2)
  }

  100% {
    transform: scaleY(1)
  }
}

@keyframes ball-circle-one {
  0% {
    transform: translate(0, 0)
  }

  25% {
    transform: translate(150%, 0)
  }

  50% {
    transform: translate(150%, 150%)
  }

  75% {
    transform: translate(0, 150%)
  }

  100% {
    transform: translate(0, 0)
  }
}

@keyframes ball-circle-two {
  0% {
    transform: translate(150%, 150%)
  }

  25% {
    transform: translate(0, 150%)
  }

  50% {
    transform: translate(0, 0)
  }

  75% {
    transform: translate(150%, 0)
  }

  100% {
    transform: translate(150%, 150%)
  }
}

@keyframes square-move-one {
  0% {
    transform: translate(50%, 50%)
  }

  8% {
    transform: translate(50%, 50%)
  }

  16% {
    transform: translate(50%, 50%)
  }

  24% {
    transform: translate(175%, 50%)
  }

  32% {
    transform: translate(175%, 50%)
  }

  40% {
    transform: translate(175%, 50%)
  }

  48% {
    transform: translate(175%, 175%)
  }

  56% {
    transform: translate(175%, 175%)
  }

  64% {
    transform: translate(175%, 175%)
  }

  72% {
    transform: translate(50%, 175%)
  }

  80% {
    transform: translate(50%, 175%)
  }

  88% {
    transform: translate(50%, 175%)
  }

  100% {
    transform: translate(50%, 50%)
  }
}

@keyframes square-move-two {
  0% {
    transform: translate(50%, 175%);
  }

  8% {
    transform: translate(50%, 175%);
  }

  16% {
    transform: translate(50%, 175%);
  }

  24% {
    transform: translate(50%, 50%);
  }

  32% {
    transform: translate(50%, 50%);

  }

  40% {
    transform: translate(50%, 50%);

  }

  48% {
    transform: translate(175%, 50%);

  }

  56% {
    transform: translate(175%, 50%);

  }

  64% {
    transform: translate(175%, 50%);

  }

  72% {
    transform: translate(175%, 175%);

  }

  80% {
    transform: translate(175%, 175%);

  }

  88% {
    transform: translate(175%, 175%);

  }

  100% {
    transform: translate(50%, 175%);

  }
}

@keyframes square-move-three {
  0% {
    transform: translate(175%, 175%);
  }

  8% {
    transform: translate(175%, 175%);
  }

  16% {
    transform: translate(175%, 175%);
  }

  24% {
    transform: translate(50%, 175%);
  }

  32% {
    transform: translate(50%, 175%);
  }

  40% {
    transform: translate(50%, 175%);
  }

  48% {
    transform: translate(50%, 50%);
  }

  56% {
    transform: translate(50%, 50%);
  }

  64% {
    transform: translate(50%, 50%);
  }

  72% {
    transform: translate(175%, 50%);
  }

  80% {
    transform: translate(175%, 50%);
  }

  88% {
    transform: translate(175%, 50%);
  }

  100% {
    transform: translate(175%, 175%);
  }
}

@keyframes ball-wave {
  0% {
    transform: translateY(0%)
  }

  25% {
    transform: translateY(50%)
  }

  50% {
    transform: translateY(0%)
  }

  75% {
    transform: translateY(-50%)
  }

  100% {
    transform: translateY(0%)
  }
}

@keyframes take-turn {
  0% {
  }

  50% {
    transform: translate(100%, 0);
  }

  100% {
  }
}

@keyframes take-turn-one {
  0% {
    opacity: .4
  }

  50% {
    transform: translate(14px, 0) rotate(180deg);
    opacity: 1;
    border-radius: 100%;
  }

  100% {
    opacity: .4
  }
}

@keyframes take-turn-two {
  0% {
    opacity: 1;
  }

  50% {
    transform: translate(-14px, 0) rotate(-180deg);
    border-radius: 100%;
    opacity: .4;
  }

  100% {
    opacity: 1;
  }
}

.lee-loading-circle {
  border: solid 2px currentColor;
  border-radius: 100%;
  border-top-color: transparent;
  animation: circle .6s infinite linear;
}

.lee-loading-circle-side {
  border: solid 2px #ddd;
  border-radius: 100%;
  border-top-color: currentColor;
  animation: circle .6s infinite linear;
}

.lee-loading-circle-arrow {
  border: solid 2px currentColor;
  border-radius: 100%;
  border-top-color: transparent;
  border-bottom-color: transparent;
  animation: circle-arrow .6s infinite linear;
  position: relative;
}

.lee-loading-circle-arrow:before,
.lee-loading-circle-arrow:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-color: transparent;
  border-width: 5px;
  border-top-color: currentColor;
}

.lee-loading-circle-arrow:before {
  transform: rotate(120deg);
  right: 10%;
  top: 0
}

.lee-loading-circle-arrow:after {
  transform: rotate(-30deg);
  left: 10%;;
  bottom: 0
}

@keyframes circle {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

@keyframes circlerev {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(-360deg)
  }
}

@keyframes circle-arrow {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(-360deg)
  }
}

@keyframes tm {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .2;
  }

  100% {
    opacity: 1
  }
}

.lee-loading-progress {
  padding-bottom: .3em;
  height: inherit;
  border-radius: 3px;
  background: #ddd;
  position: relative;
}

.lee-loading-progress span {
  width: 0px;
  height: 100%;
  display: block;
  background: currentColor;
  border-radius: 3px;
  animation: progress 1.6s infinite linear;
  position: absolute;
  top: 0;
}

.lee-loading-zebra-crossing {
  padding-bottom: .3em;
  height: inherit;
  border-radius: 3px;
  background: #ddd;
  position: relative;
}

.lee-loading-zebra-crossing span {
  width: 0px;
  height: 100%;
  border-radius: 3px;
  animation: progress1 1.6s infinite linear;
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%);
  background-color: currentColor;
  background-size: 6px;
  position: absolute;
  top: 0;

}

@keyframes progress {
  100% {
    width: 100%;
  }
}

@keyframes progress1 {
  100% {
    width: 100%;
    background-position: 54px 0
  }
}

@keyframes wave {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}


.lee-loading-flip-block {
  perspective: 80px;
  position: relative;
}

.lee-loading-flip-block:before {
  transform-style: preserve-3d;
  animation: flip 2.6s infinite linear;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 10;
  content: '';
  background: currentColor;
}

.lee-loading-flip-block:after {
  transform-style: preserve-3d;
  animation: flip 2.6s infinite linear;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 8;
  content: '';
  background: #fc0;
}

.lee-loading-box {
  perspective: 100px;
}

.lee-loading-box .boxn {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  position: relative;
  transform-origin: 50% 50%;
  animation: flip 2.6s infinite linear;
}

.lee-loading-box span {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.lee-loading-box span:nth-child(1) {
  background: currentColor;
  opacity: .3;
  transform: translateZ(calc(1em * 1 / 2));
}

.lee-loading-box span:nth-child(2) {
  background: currentColor;
  opacity: .3;
  transform: translateZ(calc(1em * -1 / 2));
}

.lee-loading-box span:nth-child(3) {
  background: currentColor;
  opacity: .8;
  transform: rotateX(90deg) translateZ(calc(1em * 1 / 2));
}

.lee-loading-box span:nth-child(4) {
  background: currentColor;
  opacity: .8;
  transform: rotateX(90deg) translateZ(calc(1em * -1 / 2));
}

.lee-loading-box span:nth-child(5) {
  opacity: .6;
  background: currentColor;
  transform: rotateY(90deg) translateZ(calc(1em * -1 / 2));
}

.lee-loading-box span:nth-child(6) {
  background: currentColor;
  opacity: .6;
  transform: rotateY(90deg) translateZ(calc(1em * 1 / 2));
}

@keyframes flip {
  0% {
    transform: rotateX(0deg) rotateY(0deg);
  }

  25% {
    transform: rotateX(180deg) rotateY(0deg);
  }

  50% {
    transform: rotateX(180deg) rotateY(180deg);
  }

  75% {
    transform: rotateX(0deg) rotateY(180deg);
  }

  100% {
    transform: rotateX(0deg) rotateY(0deg);
  }
}

.lee-loading-swim-block {
  position: relative;
}

.lee-loading-swim-block span {
  position: absolute;
  display: block;
  background: currentColor;
  animation: circle 1.6s infinite linear;
}

.lee-loading-swim-block span:nth-child(1) {
  width: 2px;
  height: 100%;
  left: 0;
  top: 0;
}

.lee-loading-swim-block span:nth-child(2) {
  height: 2px;
  width: 100%;
  left: 0;
  top: 0;
}

.lee-loading-swim-block span:nth-child(3) {
  width: 2px;
  height: 100%;
  left: 100%;
  top: 0;
}

.lee-loading-swim-block span:nth-child(4) {
  height: 2px;
  width: 100%;
  left: 0;
  top: calc(100% - 2px);
}

.lee-loading-croll-block {
  position: relative;
  box-sizing: border-box;
  animation: circlerev 1s infinite linear;
}


.lee-loading-croll-block span {
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  width: 0%;
  height: 0%;
  transform: translate(-50%, -50%);
  position: absolute;
  display: block;
  border: solid 1px currentColor;
  animation: scale 1s infinite linear;
}

.lee-loading-croll-block span:nth-child(2) {
  animation-delay: .2s
}

.lee-loading-croll-block span:nth-child(3) {
  animation-delay: .4s
}

.lee-loading-croll-block span:nth-child(4) {
  animation-delay: .6s
}

.lee-loading-croll-block span:nth-child(5) {
  animation-delay: .8s
}

@keyframes scale {
  100% {
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: translate(-50%, -50%) rotate(180deg);

  }
}

.lee-loading-wave {
  position: relative;
  box-sizing: border-box;
  border: solid 2px currentColor;
  overflow: hidden;
  border-radius: 100%;
}

.lee-loading-wave span {
  display: block;
  width: 100%;
  height: 100%;
  animation: wave 8.6s infinite linear;
}

.lee-loading-wave span:before {
  animation: circle 2.6s infinite linear;
  height: 1000%;
  width: 1000%;
  position: absolute;
  border-radius: 42%;
  top: 100%;
  left: 50%;
  margin-left: -500%;
  z-index: 8;
  background: currentColor;
  content: '';
  opacity: .4
}

.lee-loading-wave span:after {
  animation: circle 2.6s infinite linear;
  height: 600%;
  width: 600%;
  position: absolute;
  border-radius: 42%;
  top: 98%;
  left: 50%;
  margin-left: -300%;
  z-index: 10;
  background: currentColor;
  content: '';
}

</style>
