<template>
  <div class="lee-pull-refresh">
    <div class="lee-pull-refresh_track"
         :style="'transition-duration: '+(isDown?0:300)+'ms;transform: translateY('+scrollY+'px);'"
         @mousedown="mouseDown" @mousemove="mouseMove" @mouseup="mouseUp" @touchstart="touchStart"
         @touchmove="touchMove" @touchend="touchEnd">
      <div class="lee-pull-refresh_header">{{status}}</div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
import {toRefs,ref} from 'vue'

export default {
    name: 'LeePullRefresh',
    inheritAttrs: false,
    setup(props, {emit}) {
        const {pullingText, loosingText, loadingText, successText} = toRefs(props)
        const isDown = ref(false)
        const status = ref(null)
        const scrollY = ref(0)
        const oldY = ref(0)
        const mouseDown = (e) => {
            isDown.value = true
            oldY.value = e.pageY
        }
        const mouseMove = (e) => {
            if (isDown.value) {
                scrollY.value = (e.pageY - oldY.value) * 0.3
                if (scrollY.value <= 0) {
                    scrollY.value = 0
                }
                if (scrollY.value > 0) {
                    status.value = pullingText.value
                }
                if (scrollY.value >= 56) {
                    status.value = loosingText.value
                }
            }
        }
        const mouseUp = () => {
            isDown.value = false
            if (scrollY.value >= 56) {
                scrollY.value = 56
                status.value = loadingText.value
                setTimeout(() => {
                    status.value = successText.value
                }, 2000)
                setTimeout(() => {
                    scrollY.value = 0
                    status.value = ''
                    emit('refresh')
                }, 2200)
            } else {
                scrollY.value = 0
                status.value = ''
            }
        }
        const touchStart = (e) => {
            isDown.value = true
            oldY.value = e.targetTouches[0].pageY
        }
        const touchMove = (e) => {
            if (isDown.value) {
                scrollY.value = (e.targetTouches[0].pageY - oldY.value) * 0.3
                if (scrollY.value <= 0) {
                    scrollY.value = 0
                }
                if (scrollY.value > 0) {
                    status.value = pullingText.value
                }
                if (scrollY.value >= 56) {
                    status.value = loosingText.value
                }
            }
        }
        const touchEnd = () => {
            isDown.value = false
            if (scrollY.value >= 56) {
                scrollY.value = 56
                status.value = loadingText.value
                setTimeout(() => {
                    status.value = successText.value
                }, 2000)
                setTimeout(() => {
                    scrollY.value = 0
                    status.value = ''
                    emit('refresh')
                }, 2200)
            } else {
                scrollY.value = 0
                status.value = ''
            }
        }

        return {
            status, isDown, scrollY, mouseDown, mouseMove, mouseUp, touchStart, touchMove, touchEnd

        }
    },
    props: {
        pullingText: {
            type: String,
            default: '下拉即可刷新...'
        },
        loosingText: {
            type: String,
            default: '释放即可刷新...'
        },
        loadingText: {
            type: String,
            default: '加载中...'
        },
        successText: {
            type: String,
            default: '刷新成功'
        }

    }
};
</script>
<style>
.lee-pull-refresh_track {
  position: relative;
  transition-property: transform;
}

.lee-pull-refresh_header {
  position: absolute;
  left: 0;
  width: 100%;
  height: 56px;
  overflow: hidden;
  color: #999;
  font-size: 14px;
  line-height: 56px;
  text-align: center;
  transform: translateY(-100%);
}
</style>
