<template>
  <div class="lee-cell" :class="{'clickable':isLink&&!disabled}" @click="go" v-bind="$attrs">
    <i class="lee-cell-left__icon lee-icon" :class="'lee-icon-'+icon" v-if="icon"></i>
    <div class="lee-cell__title">
      <span v-if="title">{{title}}</span>
      <div class="lee-cell__label" v-if="label">{{label}}</div>
      <slot name="title"></slot>
    </div>
    <div class="lee-cell__footer" v-if="footer||haveSlotFooter">
      <template v-if="footer">{{footer}}</template>
      <template v-else>
        <slot name="footer"></slot>
      </template>
    </div>
    <template v-if="!isLink">
      <div class="lee-cell-right__icon">
        <slot name="right-icon"></slot>
      </div>
    </template>
    <template v-else>
      <i class="lee-cell-right__icon lee-icon lee-icon-arrow-left" v-if="arrowDirection==='left'"></i>
      <i class="lee-cell-right__icon lee-icon lee-icon-arrow-up" v-else-if="arrowDirection==='up'"></i>
      <i class="lee-cell-right__icon lee-icon lee-icon-arrow-down" v-else-if="arrowDirection==='down'"></i>
      <i class="lee-cell-right__icon lee-icon lee-icon-arrow-right" v-else></i>
    </template>


  </div>
</template>
<script>
import {toRefs, computed} from 'vue'
import {useRouter} from 'vue-router'

export default {
    name: 'LeeCell',
    inheritAttrs: false,
    setup(props, {slots}) {
        const router = useRouter()
        const {isLink, url, to} = toRefs(props)
        const go = () => {
            if (isLink.value) {
                if (url.value) {
                    window.open(url.value)
                }
                if (to.value) {
                    router.push(to.value)
                }
            }
        }
        const haveSlotFooter = computed(() => {
            return slots.footer != undefined
        })
        return {
            go, haveSlotFooter
        }
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        footer: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        isLink: {
            type: Boolean,
            default: false
        },
        url: {
            type: String,
            default: ''
        },
        to: {
            type: String,
            default: ''
        },
        arrowDirection: {
            type: String,
            default: 'right'
        },
        icon: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
};
</script>
<style>
.lee-cell {
  font-size: 14px;
  /*line-height: 20px;*/
  background: var(--lee-bg-white);
  display: flex;
  align-items: center;
  padding: 12px 16px;
  color: #333;
  position: relative;
}

.lee-cell:after {
  content: '';
  position: absolute;
  height: 1px;
  bottom: 0;
  left: 16px;
  right: 16px;
  background: rgba(0, 0, 0, .04);
}

.lee-cell:last-child:after {
  content: none;
}


.lee-cell__title, .lee-cell__footer {
  flex: 1;
}

.lee-cell__footer {
  display: flex;
  justify-content: flex-end;
  color: var(--lee-gray);
}


.lee-cell-right__icon {
  color: var(--lee-gray) !important;
  font-size: 14px;
  margin-left: 6px;
}

.lee-cell-left__icon {
  font-size: 14px;
  margin-right: 6px;
}

.lee-cell__label {
  color: var(--lee-gray);
  font-size: 12px;
  margin-top: 6px;
}

.lee-cell .lee-badge {
  transform: translate(0)
}

.lee-cell .lee-icon {
  font-size: 16px;
}
</style>
