<template>
  <div class="lee-tag" v-show="show" :style="colorStyle" :class="[sizeClass,typeClass,roundClass,plainClass,markClass]">
    <div class="lee-tag__content" :style="textColorStyle">
      <slot></slot>
      <i class="lee-icon lee-icon-close" v-if="closeAble" @click="close"></i>
    </div>
  </div>
</template>
<script>
import {toRefs, computed} from 'vue'

export default {
    name: 'LeeTag',
    inheritAttrs: false,
    setup(props, {emit}) {
        const {color, textColor, type, size, plain, mark, round} = toRefs(props)
        const close = () => {
            emit('close')
        }
        const textColorStyle = computed(() => {
            if (textColor.value) {
                return 'color:'+textColor.value
            } else {
                if(plain.value){
                    return 'color:'+color.value
                }else{
                    return ''

                }
            }
        })
        const colorStyle = computed(() => {
            if (plain.value) {
                if (color.value) {
                    return 'border-color:' + color.value + ";color:" + color.value

                } else {
                    return ''
                }
            } else {
                if (color.value) {
                    return 'background:' + color.value
                } else {
                    return ''
                }
            }
        })

        const sizeArray = ['large', 'medium']
        const typeArray = ['default', 'primary', 'success', 'danger', 'warning']
        const sizeClass = computed(() => {
            if (sizeArray.indexOf(size.value) < 0) {
                return ''
            } else {
                return 'lee-tag--' + size.value
            }
        })
        const typeClass = computed(() => {
            if (typeArray.indexOf(type.value) < 0) {
                return 'lee-tag--default'
            } else {
                return 'lee-tag--' + type.value
            }
        })
        const roundClass = computed(() => {
            if (round.value) {
                return 'lee-tag--round'
            } else {
                return ''
            }
        })
        const plainClass = computed(() => {
            if (plain.value) {
                return 'lee-tag--plain'
            } else {
                return ''
            }
        })
        const markClass = computed(() => {
            if (mark.value) {
                return 'lee-tag--mark'
            } else {
                return ''
            }
        })


        return {
            sizeClass, typeClass, roundClass, plainClass, markClass, close, colorStyle,textColorStyle
        }
    },
    props: {
        color: {
            type: String,
            default: ''
        },
        textColor: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'default'
        },
        size: {
            type: String,
            default: ''
        },
        show: {
            type: Boolean,
            default: true
        },
        plain: {
            type: Boolean,
            default: false
        },
        mark: {
            type: Boolean,
            default: false
        },
        closeAble: {
            type: Boolean,
            default: false
        },
        round: {
            type: Boolean,
            default: false
        },
    }
};
</script>
<style>
.lee-tag {
  display: inline-block;
  padding: 0 4px;
  color: var(--lee-white);
  font-size: 12px;
  border-radius: 2px;
}

.lee-tag .lee-tag__content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.lee-tag .lee-tag__content .lee-icon {
  font-size: inherit;
  margin-left: 6px;
}


.lee-tag--default {
  color: var(--lee-gray);
  background: var(--lee-gray-light);
}

.lee-tag--primary {
  color: var(--lee-white);
  background: var(--lee-blue);
}

.lee-tag--success {
  color: var(--lee-white);
  background: var(--lee-green);
}

.lee-tag--warning {
  color: var(--lee-white);
  background: var(--lee-orange);
}

.lee-tag--danger {
  color: var(--lee-white);
  background: var(--lee-red);
  border: solid 1px var(--lee-red);
}

.lee-tag--large {
  padding: 4px 8px;
  font-size: 14px;
  border-radius: 4px;
}

.lee-tag--medium {
  padding: 2px 6px;
}


.lee-tag--plain {
  background: var(--lee-white);
  border: solid 1px #dedede
}

.lee-tag--plain.lee-tag--primary {
  color: var(--lee-blue);
  border-color: var(--lee-blue);
}

.lee-tag--plain.lee-tag--success {
  color: var(--lee-green);
  border-color: var(--lee-green);
}

.lee-tag--plain.lee-tag--default {
  color: var(--lee-gray);
  border-color: var(--lee-gray-light);
}

.lee-tag--plain.lee-tag--danger {
  color: var(--lee-red);
  border-color: var(--lee-red);
}

.lee-tag--plain.lee-tag--warning {
  color: var(--lee-orange);
  border-color: var(--lee-orange);
}


.lee-tag--round {
  border-radius: 999px;
}

.lee-tag--mark {
  border-radius: 0 999px 999px 0;
}


</style>
