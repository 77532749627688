<template>
  <lee-popup round class='lee-dialog' v-model="show" :close-on-click-overlay="false" @onOpen="open" @onClose="close">
    <div class="lee-dialog__header">{{title}}</div>
    <div class="lee-dialog__content">
      <div class="lee-dialog__message" :class="classStyle" v-html="message"></div>
    </div>
    <div class="lee-dialog__footer">
      <lee-button @click="cancel" size="large" class="lee-dialog__cancel" v-if="showCancelButton"
                  :style="cancelColor">
        {{cancelButtonText}}
      </lee-button>
      <div class="lee-dialog_line" v-if="showCancelButton&&showConfirmButton"></div>
      <lee-button @click="confirm" size="large" class="lee-dialog__confirm" v-if="showConfirmButton"
                  :style="confirmColor">
        {{confirmButtonText}}
      </lee-button>
    </div>
  </lee-popup>
</template>
<script>

import {toRgb} from './lee'
import {toRefs, computed} from 'vue'
import LeePopup from '@/leeplus/LeePopup'
import LeeButton from '@/leeplus/LeeButton'

export default {
    name: 'LeeDialog',
    inheritAttrs: false,
    emit: ['update:modelValue', 'onOpen', 'onClose', 'beforeClose', 'onConfirm', 'onCancel'],
    components: {
        LeePopup, LeeButton
    },
    setup(props, {emit}) {
        const {modelValue, cancelButtonColor, confirmButtonColor, messageAlign} = toRefs(props)

        const show = computed({
            get: () => modelValue.value,
            set: val => emit('update:modelValue', val)
        })
        const classStyle = computed(() => {
            let classArray = ['left', 'center', 'right']
            if (classArray.indexOf(messageAlign.value) > -1) {
                return 'lee-dialog__message--' + messageAlign.value
            } else {
                return 'lee-dialog__message--center'
            }
        })
        const cancelColor = computed(() => {
            if (toRgb(cancelButtonColor.value) === 'rgb(150,151,153)') {
                return ''
            } else {
                return 'color:' + cancelButtonColor.value
            }
        })
        const confirmColor = computed(() => {
            if (toRgb(confirmButtonColor.value) === 'rgb(25,137,250)') {
                return ''
            } else {
                return 'color:' + confirmButtonColor.value
            }
        })
        const close = () => {
            emit('onClose')
            props.onClose && props.onClose()
        }
        const open = () => {
            emit('onOpen')
            props.onOpen && props.onOpen()
        }

        const cancel = () => {
            emit('update:modelValue', false)
            emit('onCancel')
            props.onCancel && props.onCancel()
            setTimeout(() => {
                emit('onClose')
            }, 300)

        }
        const confirm = () => {
            emit('update:modelValue', false)
            emit('onConfirm')
            props.onConfirm&& props.onConfirm()
            setTimeout(() => {
                emit('onClose')
            }, 300)
        }

        return {
            show, classStyle, cancelColor, confirmColor, cancel, confirm, close, open
        }
    },
    props: {
        onOpen: Function,
        onClose: Function,
        onCancel: Function,
        onConfirm: Function,
        modelValue: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        message: {
            type: String,
            default: ''
        },
        width: {
            type: String,
            default: '320'
        },
        messageAlign: {
            type: String,
            default: 'center'//left right  center
        },
        showConfirmButton: {
            type: Boolean,
            default: true
        },
        showCancelButton: {
            type: Boolean,
            default: false
        },
        confirmButtonText: {
            type: String,
            default: '确认'
        },
        confirmButtonColor: {
            type: String,
            default: '#1989fa'
        },
        cancelButtonText: {
            type: String,
            default: '取消'
        },
        cancelButtonColor: {
            type: String,
            default: '#969799'
        },

    }
};
</script>
<style>
.lee-dialog__footer {
  border-top: solid 1px var(--lee-gray-light);
  display: flex;
  position: relative;
}

.lee-dialog__confirm, .lee-dialog__cancel {
  flex: 1;
  height: 48px;
  border: 0;
}

.lee-dialog__confirm {
  color: var(--lee-blue);
}

.lee-dialog__cancel {
  color: var(--lee-gray);
}

.lee-dialog__cancel + .lee-dialog_line {
  position: absolute;
  width: 1px;
  height: 100%;
  left: 50%;
  top: 0;
  background: var(--lee-gray-light);
  z-index: 2;
}

.lee-dialog {
  top: 45%;
  width: 320px;
  overflow: hidden;
  font-size: 16px;
  background: var(--lee-white);
}

.lee-dialog__header {
  color: var(--lee-back-dark);
  padding-top: 26px;
  font-weight: 600;
  text-align: center;
}

.lee-dialog__message {
  color: var(--lee-back-dark);
  max-height: 60vh;
  padding: 26px 24px;
  overflow-y: auto;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
  text-align: center;
  word-wrap: break-word;
  -webkit-overflow-scrolling: touch;
}
.lee-dialog__message p{
  margin-bottom: 8px;}

.lee-dialog__message--left {
  text-align: center;
}

.lee-dialog__message--center {
  text-align: center;
}

.lee-dialog__message--right {
  text-align: center;
}


.lee-dialog__header + .lee-dialog__content .lee-dialog__message {
  padding-top: 8px;
  color: var(--lee-back-light);
}
</style>
