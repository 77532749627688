<template>
  <button  v-bind="$attrs" @click="go" :style="checkStyle" class="lee-button"
          :class="[{'lee-button-plain':plain,'lee-button-disabled':disabled,'lee-button-round':round,'lee-button-square':square},typeClass,sizeClass]">
    <div class="lee-button-content">
      <template v-if="loading">
        <div class="lee-button-loading">
          <lee-loading :type="loadingType"/>
        </div>
      </template>
      <template v-if="!loading&&icon">
        <i class="lee-button-icon lee-icon" v-if="icon.indexOf('http')>-1">
          <img :src="icon" :style="'height:'+size+'px'">
        </i>
        <i class="lee-button-icon lee-icon" :class="'lee-icon-'+icon" v-if="icon.indexOf('http')<0&&icon"></i>
      </template>
      <div class="lee-button-text" v-if="haveSlot">
        <slot></slot>
      </div>
    </div>
  </button>
</template>
<script>
import {toRgb} from './lee'
//import {useRouter} from 'vue-router' //这个方法当其它组件包含这个的时候就提示错误，比如dialog包含了button
import router from '@/router'
import {toRefs, computed} from 'vue'
import LeeLoading from '@/leeplus/LeeLoading';
export default {
    name: 'LeeButton',
    inheritAttrs: false,
    components:{
        LeeLoading
    },
    setup(props, {slots}) {
        //const router = useRouter()
        const {type, size, to, url, color, plain,textColor} = toRefs(props)
        const typeArray = ['primary', 'success', 'warning', 'danger']
        const sizeArray = ['large', 'normal', 'small', 'mini']
        const typeClass = computed(() => {
            if (typeArray.indexOf(type.value) > -1) {
                return 'lee-button-' + type.value
            } else {
                return 'lee-button-default'
            }
        })
        const sizeClass = computed(() => {
            if (sizeArray.indexOf(size.value) > -1) {
                return 'lee-button-' + size.value
            } else {
                return 'lee-button-normal'
            }
        })
        const haveSlot = computed(() => {
            return slots.default != undefined
        })
        const checkStyle = computed(() => {
            if (!plain.value) {
                if (toRgb(color.value) === 'rgb(255,255,255)') {
                    return ''
                } else {
                    return 'background:' + color.value + ';color:'+textColor.value+';border-color:' + color.value
                }
            } else {
                if (toRgb(color.value) === 'rgb(255,255,255)') {
                    return ''
                } else {
                    return 'color:' + color.value + ';border-color:' + color.value
                }
            }

        })
        const go = () => {
            if (to.value || url.value) {
                if (url.value) {
                    window.open(url.value)
                }
                if (to.value) {
                    router.push(to.value)
                }
            }
        }
        return {
            typeClass, sizeClass, haveSlot, go, checkStyle
        }
    },
    props: {
        color: {
            type: String,
            default: '#fff'
        },
        textColor: {
            type: String,
            default: '#000'
        },
        type: {
            type: String,
            default: 'default'
        },
        size: {
            type: String,
            default: 'normal'
        },
        plain: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        loadingType: {
            type: String,
            default: 'circle'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        square: {
            type: Boolean,
            default: false
        },
        round: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: ''
        },
        url: {
            type: String,
            default: ''
        },
        to: {
            type: String,
            default: ''
        },
    }
};
</script>
<style>
.lee-button {
  position: relative;
  display: inline-block;
  height: 44px;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  transition: opacity .2s;
  appearance: none;
  border: none;
  outline: none;
}

.lee-button .lee-button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  z-index: 1;
}

.lee-button:before {
  left: 0;
  top: 0;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .1);
  opacity: 0;
  border-radius: 4px;
}

.lee-button:active:before {
  opacity: 1;
}

.lee-button-default {
  color: var(--lee-back);
  background: var(--lee-white);
  border: solid 1px #dedede;
}

.lee-button-primary {
  color: var(--lee-white);
  background: var(--lee-blue);
  border: solid 1px var(--lee-blue);
}

.lee-button-success {
  color: var(--lee-white);
  background: var(--lee-green);
  border: solid 1px var(--lee-green);
}

.lee-button-warning {
  color: var(--lee-white);
  background: var(--lee-orange);
  border: solid 1px var(--lee-orange);
}

.lee-button-danger {
  color: var(--lee-white);
  background: var(--lee-red);
  border: solid 1px var(--lee-red);
}

.lee-button-large {
  width: 100%;
  height: 50px;
}

.lee-button-normal {
  padding: 0 15px;
  font-size: 14px;
}

.lee-button-small {
  height: 32px;
  padding: 0 8px;
  font-size: 12px;
}

.lee-button-mini {
  height: 24px;
  padding: 0 4px;
  font-size: 10px;
}

.lee-button-plain {
  background: var(--lee-white);
}

.lee-button-plain.lee-button-primary {
  color: var(--lee-blue);
}

.lee-button-plain.lee-button-success {
  color: var(--lee-green);
}

.lee-button-plain.lee-button-default {
  color: var(--lee-back);
}

.lee-button-plain.lee-button-danger {
  color: var(--lee-red);
}

.lee-button-plain.lee-button-warning {
  color: var(--lee-orange);
}

.lee-button-disabled {
  pointer-events: none;
  opacity: .3;
}


.lee-button-square {
  border-radius: 0px;
}

.lee-button-round {
  border-radius: 999px;
}

.lee-button-icon + .lee-button-text, .lee-button-loading + .lee-button-text {
  margin-left: 6px;
}

.lee-button-primary .lee-loading-icon, .lee-button-success .lee-loading-icon, .lee-button-danger .lee-loading-icon, .lee-button-warning .lee-loading-icon {
  color: var(--lee-white);
}

</style>
