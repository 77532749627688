<template>
  <template v-if="teleport===''">
      <transition name="fade" appear>
        <div class="lee-mark" v-if="overlay&&modelValue" @click="closeMark" :style="{'--d':duration+'s'}"></div>
      </transition>
      <transition :name="whichEffect" appear>
        <div class="lee-popup" :class="[positionClass,roundClass]" v-bind="$attrs" v-if="modelValue"
             :style="{'--d':duration+'s'}">
          <i class="lee-popup-close-icon lee-icon"
             :class="['lee-icon-'+closeIcon,'lee-popup-close-icon-'+closeIconPosition]" v-if="closeable"
             @click="close"></i>
          <slot></slot>
        </div>
      </transition>
  </template>
  <template v-else>
    <teleport :to="teleport">
      <transition name="fade" appear>
        <div class="lee-mark" v-if="overlay&&modelValue" @click="closeMark" :style="{'--d':duration+'s'}"></div>
      </transition>
      <transition :name="whichEffect" appear>
        <div class="lee-popup" :class="[positionClass,roundClass]" v-bind="$attrs" v-if="modelValue"
             :style="{'--d':duration+'s'}">
          <i class="lee-popup-close-icon lee-icon"
             :class="['lee-icon-'+closeIcon,'lee-popup-close-icon-'+closeIconPosition]" v-if="closeable"
             @click="close"></i>
          <slot></slot>
        </div>
      </transition>
    </teleport>
  </template>
</template>
<script>
import {toRefs, computed, ref, watchEffect} from 'vue'

export default {
    name: 'LeePopup',
    inheritAttrs: false,
    emit: ['onOpen', 'onClose', 'beforeClose'],
    setup(props, {emit}) {
        const {position, round, modelValue, closeOnClickOverlay} = toRefs(props)
        const close = () => {
            emit('update:modelValue', false)
            emit('onClose')
        }
        const closeMark = () => {
            if (closeOnClickOverlay.value) {
                emit('update:modelValue', false)
                emit('onClose')
            }
        }
        const positionArray = ['center', 'top', 'bottom', 'left', 'right']
        const whichEffect = computed(() => {
            const effect = ref('')
            if (positionArray.indexOf(position.value) < 0) {
                effect.value = 'center'
            } else {
                effect.value = position.value
            }
            if (effect.value === 'center') {
                return 'fade'
            } else {
                return effect.value
            }
        })
        const positionClass = computed(() => {
            if (positionArray.indexOf(position.value) < 0) {
                return 'lee-popup-center'
            } else {
                return 'lee-popup-' + position.value
            }
        })
        const roundClass = computed(() => {
            if (round.value) {
                return 'lee-popup-round'
            } else {
                return ''
            }
        })
        watchEffect(() => {
            if (modelValue.value) {
                emit('onOpen')
            }
        })

        return {
            closeMark, close, positionClass, roundClass, whichEffect
        }
    },
    props: {
        modelValue: {
            type: Boolean,
            default: false
        },
        overlay: {
            type: Boolean,
            default: true
        },
        position: {
            type: String,
            default: 'center'
        },
        duration: {
            type: [Number, String],
            default: '0.3'
        },
        round: {
            type: Boolean,
            default: false
        },
        closeOnClickOverlay: {
            type: Boolean,
            default: true
        },
        closeable: {
            type: Boolean,
            default: false
        },
        closeIcon: {
            type: String,
            default: 'close'
        },
        closeIconPosition: {
            type: String,
            default: 'top-right'
        }
        ,
        teleport: {
            type: String,
            default: 'body'
        }
    }
};
</script>
<style>
.lee-mark {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, .7);
  z-index: 999;
}

.lee-popup {
  position: fixed;
  max-height: 100%;
  overflow-y: auto;
  background: var(--lee-white);
  -webkit-overflow-scrolling: touch;
  z-index: 1000;
}

.lee-popup-top {
  width: 100%;
  top: 0;
}

.lee-popup-bottom {
  width: 100%;
  bottom: 0;
}

.lee-popup-left {
  left: 0;
  top: 0;
}

.lee-popup-right {
  right: 0;
  top: 0;
}


.lee-popup-center.lee-popup-round {
  border-radius: 16px;
}

.lee-popup-top.lee-popup-round {
  border-radius: 0 0 16px 16px;
}

.lee-popup-bottom.lee-popup-round {
  border-radius: 16px 16px 0 0;
}

.lee-popup-left.lee-popup-round {
  border-radius: 0 16px 16px 0;
}

.lee-popup-right.lee-popup-round {
  border-radius: 16px 0 0 16px;
}

.lee-popup-center {
  top: 50%;
  left: 0;
  right: 0;
  width: fit-content;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  transform: translateY(-50%);

}

.lee-popup-close-icon {
  position: absolute;
  z-index: 1;
  color: #888;
  font-size: 16px;
  cursor: pointer;
}

.lee-popup-close-icon-top-right {
  top: 16px;
  right: 16px;
}

.lee-popup-close-icon-top-left {
  top: 16px;
  left: 16px;
}

.lee-popup-close-icon-bottom-right {
  bottom: 16px;
  right: 16px;
}

.lee-popup-close-icon-bottom-left {
  bottom: 16px;
  left: 16px;
}


/*动效*/
.fade-enter-active, .fade-leave-active {
  transition: opacity var(--d);
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

.top-enter-active, .top-leave-active {
  transition: transform var(--d);
}

.top-enter-from, .top-leave-to {
  transform: translateY(-100%);
}

.top-enter-to, .top-leave-from {
  transform: translateY(0);
}

.bottom-enter-active, .bottom-leave-active {
  transition: transform var(--d);
}

.bottom-enter-from, .bottom-leave-to {
  transform: translateY(100%);
}

.bottom-enter-to, .bottom-leave-from {
  transform: translateY(0);
}

.left-enter-active, .left-leave-active {
  transition: transform var(--d);
}

.left-enter-from, .left-leave-to {
  transform: translateX(-100%);
}

.left-enter-to, .left-leave-from {
  transform: translateX(0);
}

.right-enter-active, .right-leave-active {
  transition: transform var(--d);
}

.right-enter-from, .right-leave-to {
  transform: translateX(100%);
}

.right-enter-to, .right-leave-from {
  transform: translateX(0);
}
</style>
