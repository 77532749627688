<template>
  <div class="lee-dropdown-menu" ref="menu">
    <div class="lee-dropdown-menu__bar" ref="bar" :class="{'lee-dropdown-menu__bar--opened':typeof(index)==='number'}">
      <div class="lee-dropdown-menu__item" @click="toggle(xh)"
           v-for="(item,xh) in Num" :key="xh">
        <div class="lee-dropdown-menu__title" :class="{'lee-dropdown-menu__title--active':xh===index}"
             :style="styles(xh)">
          {{checkTitle(xh)}}
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
import {toRgb} from './lee'
import {ref, toRefs, provide, onMounted, watch, getCurrentInstance} from 'vue'

export default {
    name: 'LeeDropdownMenu',
    inheritAttrs: false,
    emits: ['onOpen', 'onClose'],
    setup(props, {emit, slots}) {
        const idirection = ref('')
        const ixh = ref('')
        const Num = slots.default().length
        const menu = ref(null)
        const bar = ref(null)
        const index = ref('')
        const top = ref(0)
        const allData = {}
        const {activeColor, direction} = toRefs(props)
        const diArray = ['down', 'up']
        if (diArray.indexOf(direction.value) > -1) {
            idirection.value = direction.value
        } else {
            idirection.value = 'down'
        }
        const styles = (xh) => {
            if (toRgb(activeColor.value) === 'rgb(25,137,250)') {
                return ''
            } else {
                if (xh === index.value) {
                    return 'color:' + activeColor.value
                }
            }

        }
        slots.default().forEach((el, xh) => {
            allData[xh] = el.props.options
        })
        const toggle = (inx) => {
            if (index.value === inx) {
                index.value = ''
            } else {
                index.value = inx
            }
        }
        const resetIndex = () => {
            index.value = ''
        }
        const setIndex = (inx) => {
            index.value = inx.length > 0 ? Number(inx) : ''
        }
        onMounted(() => {
            if (idirection.value === 'down') {
                top.value = menu.value.getBoundingClientRect().top + bar.value.getBoundingClientRect().height

            } else {
                top.value = document.documentElement.scrollHeight - menu.value.getBoundingClientRect().top
            }
            //ixh
            ixh.value = getCurrentInstance().uid
        })
        //点击外部关闭
        document.addEventListener('click', (e) => {
            //e.stopPropagation()
            if(e.target.className.indexOf('lee-dropdown-menu')<0){
                index.value = ''
            }else{
                console.log("在MENU上")
            }

        })

        watch(index, (v) => {
            if (v.length > 0) {
                emit('onOpen')
            } else {
                emit('onClose')
            }

        }, {
            immediate: true
        })

        provide('dom', {top})
        provide('setData', {index, resetIndex, setIndex, allData})
        provide('parent', toRefs(props))


        const checkTitle = (xh) => {
            let theIt = slots.default()
            let sKey = theIt[xh].props.modelValue
            let sArray = theIt[xh].props.options
            let fi = sArray.filter((el) => {
                return el.value === sKey
            })
            return fi[0].text
        }
        return {
            Num, checkTitle, toggle, index, styles, menu, bar, resetIndex, ixh
        }
    },
    props: {
        activeColor: {
            type: String,
            default: '#1989fa'
        },
        direction: {
            type: String,
            default: 'down' //down up
        },
        duration: {
            type: [Number, String],
            default: 0.3
        },
        overlay: {
            type: Boolean,
            default: true
        },
        closeOnClickOverlay: {
            type: Boolean,
            default: true
        }

    }
};
</script>
<style>
.lee-dropdown-menu__bar {
  position: relative;
  display: flex;
  height: 48px;
  background: var(--lee-white);
  box-shadow: 0 2px 12px rgba(100, 101, 102, .12);
}

.lee-dropdown-menu__bar--opened {
  z-index: 200;
}

.lee-dropdown-menu__item {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.lee-dropdown-menu__title {
  position: relative;
  max-width: 100%;
  padding: 0 8px;
  color: var(--lee-back-dark);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lee-dropdown-menu__title:after {
  border: 3px solid;
  border-color: transparent transparent var(--lee-gray) var(--lee-gray);
  transform: rotate(-45deg);
  opacity: .8;
  content: "";
  margin-left: 6px;
}

.lee-dropdown-menu__title--active:after {
  margin-top: 5px;
  transform: rotate(135deg);
}

.lee-dropdown-menu__title--active {
  color: var(--lee-blue);
}

.lee-dropdown-menu__title--active:after {
  border-color: transparent transparent currentColor currentColor;
}

</style>
