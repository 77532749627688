import {h, render} from "vue";
import './font/lee-icon.css'
import './lee-plus.css'

const Cell = {},
    CellGroup = {},
    Icon = {},
    Badge = {},
    Tag = {},
    Switch = {},
    Calendar = {},
    Button = {},
    Loading = {},
    Popup = {},
    Picker = {},
    DatePicker = {},
    Toast = {},
    Dialog = {},
    ActionSheet = {},
    PullRefresh = {},
    DropdownMenu = {},
    DropdownItem = {},
    Collapse = {},
    CollapseItem = {},
    IndexBar={},
    IndexAnchor={}

import LeeCell from './LeeCell.vue'
import LeeCellGroup from './LeeCellGroup.vue'
import LeeIcon from './LeeIcon.vue'
import LeeBadge from './LeeBadge.vue'
import LeeTag from './LeeTag.vue'
import LeeSwitch from './LeeSwitch.vue'
import LeeButton from './LeeButton.vue'
import LeeLoading from './LeeLoading.vue'
import LeePopup from './LeePopup.vue'
import LeeToast from './LeeToast.vue'
import LeeDialog from './LeeDialog.vue'
import LeeActionSheet from './LeeActionSheet.vue'
import LeePullRefresh from './LeePullRefresh.vue'
import LeeCalendar from './LeeCalendar.vue'
import LeeDropdownMenu from './LeeDropdownMenu.vue'
import LeeDropdownItem from './LeeDropdownItem.vue'
import LeeCollapse from './LeeCollapse.vue'
import LeeCollapseItem from './LeeCollapseItem.vue'
import LeePicker from './LeePicker.vue'
import LeeDatePicker from './LeeDatePicker.vue'
import LeeIndexBar from './LeeIndexBar.vue'
import LeeIndexAnchor from './LeeIndexAnchor.vue'


Cell.install = function (Vue) {
    Vue.component(LeeCell.name, LeeCell)
}
CellGroup.install = function (Vue) {
    Vue.component(LeeCellGroup.name, LeeCellGroup)
}
Icon.install = function (Vue) {
    Vue.component(LeeIcon.name, LeeIcon)
}
Badge.install = function (Vue) {
    Vue.component(LeeBadge.name, LeeBadge)
}
Switch.install = function (Vue) {
    Vue.component(LeeSwitch.name, LeeSwitch)
}
Button.install = function (Vue) {
    Vue.component(LeeButton.name, LeeButton)
}
Loading.install = function (Vue) {
    Vue.component(LeeLoading.name, LeeLoading)
}
Popup.install = function (Vue) {
    Vue.component(LeePopup.name, LeePopup)
}
Toast.install = function (Vue) {
    Vue.component(LeeToast.name, LeeToast)
}
Dialog.install = function (Vue) {
    Vue.component(LeeDialog.name, LeeDialog)
}
ActionSheet.install = function (Vue) {
    Vue.component(LeeActionSheet.name, LeeActionSheet)
}
PullRefresh.install = function (Vue) {
    Vue.component(LeePullRefresh.name, LeePullRefresh)
}

Tag.install = function (Vue) {
    Vue.component(LeeTag.name, LeeTag)
}
Calendar.install = function (Vue) {
    Vue.component(LeeCalendar.name, LeeCalendar)
}
Collapse.install = function (Vue) {
    Vue.component(LeeCollapse.name, LeeCollapse)
}
CollapseItem.install = function (Vue) {
    Vue.component(LeeCollapseItem.name, LeeCollapseItem)
}

DropdownItem.install = function (Vue) {
    Vue.component(LeeDropdownItem.name, LeeDropdownItem)
}
DropdownMenu.install = function (Vue) {
    Vue.component(LeeDropdownMenu.name, LeeDropdownMenu)
}
Picker.install = function (Vue) {
    Vue.component(LeePicker.name, LeePicker)
}
DatePicker.install = function (Vue) {
    Vue.component(LeeDatePicker.name, LeeDatePicker)
}
IndexBar.install = function (Vue) {
    Vue.component(LeeIndexBar.name, LeeIndexBar)
}
IndexAnchor.install = function (Vue) {
    Vue.component(LeeIndexAnchor.name, LeeIndexAnchor)
}



const showToast = (option) => {
    //默认参数
    let setOption = {
        message: LeeToast.props.message.default,
        type: LeeToast.props.type.default,
        loadingType: LeeToast.props.loadingType.default,
        icon: LeeToast.props.icon.default,
        duration: LeeToast.props.duration.default,
        onOpen: () => {
        },
        onClose: () => {
        }
    }
    if (typeof (option) === 'string') {
        option = {message: option, type: 'text'}
    }
    const myOption = Object.assign(setOption, option)
    let app = h(LeeToast, {
        ...myOption,
        modelValue: true
    });
    //将app渲染到body

    render(app, document.body)
    myOption.onOpen()
    //经过duration时间后，渲染app去掉，然后执行onClose回调
    if (myOption.duration > 0) {
        setTimeout(() => {
            myOption.onClose()
            app.component.props.modelValue = false//此方法改变组件响应数据，非常好用
            setTimeout(() => {
                render(null, document.body)
                //clearTimeout(stt.value)
            }, 300)
            //clearTimeout(st.value)
        }, myOption.duration * 1000);
    }
}

const closeToast = () => {
    const app = h(LeeToast, {
        modelValue: false
    });
    render(app, document.body)
    setTimeout(() => {
        render(null, document.body)
    }, 300)

}
const showDialog = (option) => {
    //默认参数
    let setOption = {
        title: LeeDialog.props.title.default,
        message: LeeDialog.props.message.default,
        width: LeeDialog.props.width.default,
        messageAlign: LeeDialog.props.messageAlign.default,
        showConfirmButton: LeeDialog.props.showConfirmButton.default,
        showCancelButton: LeeDialog.props.showCancelButton.default,
        confirmButtonText: LeeDialog.props.confirmButtonText.default,
        confirmButtonColor: LeeDialog.props.confirmButtonColor.default,
        cancelButtonText: LeeDialog.props.cancelButtonText.default,
        cancelButtonColor: LeeDialog.props.cancelButtonColor.default

    }
    const myOption = Object.assign(setOption, option)
    const onOpen = () => {
        myOption.onOpen()
    }
    const onClose = () => {
        app.component.props.modelValue = false
        setTimeout(()=>{
            myOption.onClose()
            render(null, document.body)
        },300)
    }
    const onCancel = () => {
        myOption.onCancel()
        onClose()
    }
    const onConfirm = () => {
        myOption.onConfirm()
        onClose()

    }
    let app = h(LeeDialog, {
        ...myOption,
        modelValue: true,
        onOpen,
        onClose,
        onCancel,
        onConfirm
    });
    //将app渲染到body
    render(app, document.body)
}
export {
    Cell,
    CellGroup,
    Icon,
    Badge,
    Switch,
    Button,
    Loading,
    Popup,
    showToast,
    closeToast,
    Toast,
    Dialog,
    showDialog,
    ActionSheet,
    PullRefresh,
    Tag,
    Calendar,
    DropdownMenu,
    DropdownItem,
    Collapse,
    CollapseItem,
    Picker,
    DatePicker,
    IndexBar,
    IndexAnchor
}
