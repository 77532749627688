import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Home',
        meta: {level: 1},
        component: () => import('@/views/Home.vue')
    },
    {
        path: '/Button',
        meta: {level: 2},
        component: () => import('@/views/Button.vue')
    },
    {
        path: '/Cell',
        meta: {level: 2},
        component: () => import('@/views/Cell.vue')
    },
    {
        path: '/Icon',
        name: 'Icon',
        meta: {level: 2},
        component: () => import('@/views/Icon.vue')
    },
    {
        path: '/IndexBar',
        name: 'IndexBar',
        meta: {level: 2},
        component: () => import('@/views/IndexBar.vue')
    },
    {
        path: '/Popup',
        meta: {level: 2},
        component: () => import('@/views/Popup.vue')
    },
    {
        path: '/DatePicker',
        meta: {level: 2},
        component: () => import('@/views/DatePicker.vue')
    },
    {
        path: '/Picker',
        meta: {level: 2},
        component: () => import('@/views/Picker.vue')
    },
    {
        path: '/Toast',
        meta: {level: 2},
        component: () => import('@/views/Toast.vue')
    },
    {
        path: '/Dialog',
        meta: {level: 2},
        component: () => import('@/views/Dialog.vue')
    },
    {
        path: '/Badge',
        meta: {level: 2},
        component: () => import('@/views/Badge.vue')
    },
    {
        path: '/Tag',
        name: 'Tag',
        meta: {level: 2},
        component: () => import('@/views/Tag.vue')
    },
    {
        path: '/Loading',
        meta: {level: 2},
        component: () => import('@/views/Loading.vue')
    },
    {
        path: '/Switch',
        meta: {level: 2},
        component: () => import('@/views/Switch.vue')
    },
    {
        path: '/ActionSheet',
        meta: {level: 2},
        component: () => import('@/views/ActionSheet.vue')
    },
    {
        path: '/PullRefresh',
        meta: {level: 2},
        component: () => import('@/views/PullRefresh.vue')
    },
    {
        path: '/Calendar',
        meta: {level: 2},
        component: () => import('@/views/Calendar.vue')
    },
    {
        path: '/DropdownMenu',
        meta: {level: 2},
        component: () => import('@/views/DropdownMenu.vue')
    },
    {
        path: '/Collapse',
        meta: {level: 2},
        component: () => import('@/views/Collapse.vue')
    },
    {
        path: '/404',
        meta: {level: 2},
        component: () => import('@/views/404'),
        hidden: true,
    },
    {path: '/:pathMatch(.*)*', redirect: '/404', hidden: true}
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
