<template>
  <router-view v-slot="{ Component }">
    <transition :name="whichTran">
      <component :is="Component"/>
    </transition>
  </router-view>
</template>
<script setup>
import {useRouter} from 'vue-router'
import {ref} from 'vue'

const router = useRouter()
const whichTran = ref('fade')

router.afterEach((to, from) => {
    const toLevel = to.meta.level
    const fromLevel = from.meta.level
    whichTran.value = toLevel > fromLevel ? 'slide-left' : 'slide-right'
})


</script>
<style>
/* 路由转场样式 */
.slide-right-enter-active,.slide-left-enter-active {
    transition: all .3s ease-in-out;
}
.slide-left-leave-active,.slide-right-leave-active {
    transition: all .3s ease-in-out;
}

.slide-right-enter-from {
    transform: translateX(-100%);
}
.slide-right-leave-active {
    transform: translateX(60%);
}
.slide-left-enter-from {
    transform: translateX(100%);
}
.slide-left-leave-active {
    transform: translateX(-60%);
}


</style>
